import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Tooltip,
  IconButton,
  TextField,
  TableSortLabel,
  Button,
  Pagination,
  Typography,
  InputAdornment,
} from "@mui/material";
import { Edit, Delete, Search, Clear, RemoveRedEye } from "@mui/icons-material";
import { EditPropertyForm, DeleteProperty, PopUpWindow,AddPropertyForm, ViewProperty} from "../";
import io from "socket.io-client";


const ioClient = io("https://maliarakis-server.rhodesislandpass.com/");

const formatDateWithTime = (date) => {
  date = new Date(date);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${day}/${month}/${year} ${hours}:${minutes}`;
};

const PropertiesTable = () => {
  const [properties, setProperties] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalProperties, setTotalProperties] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [sortField, setSortField] = useState("name");
  const [sortOrder, setSortOrder] = useState(1);
  const user = JSON.parse(localStorage.getItem("user"));
  const [viewPopUpOpen, setViewPopUpOpen] = useState(false);
  const [editPopUpOpen, setEditPopUpOpen] = useState(false);
  const [editProperty, setEditProperty] = useState(null);
  const [deletePopUpOpen, setDeletePopUpOpen] = useState(false);
  const [deleteProperty, setDeleteProperty] = useState(null);

  useEffect(() => {
    fetchProperties(search, currentPage, sortField, sortOrder);
  }, [currentPage, sortField, sortOrder]);

  useEffect(() => {
    const handleUpdateProperty = (property) => {
      setProperties((properties) => {
        const index = properties.findIndex((p) => p._id === property._id);
        if (index !== -1) {
          properties[index] = property;
        } 
        return [...properties];
      });
    };

    const handleDeleteProperty = (property) => {
     
    
      setProperties((properties) => {
        return properties.filter((p) => p._id !== property._id);
      }

      );
    };

    const handleAddProperty = (property) => {
      setProperties((properties) => {
        return [property, ...properties];
      });
    };  

    ioClient.on("updatedProperty", handleUpdateProperty);
    ioClient.on("deletedProperty", handleDeleteProperty);
    ioClient.on("createdProperty", handleAddProperty);

    return () => {
      ioClient.off("updatedProperty", handleUpdateProperty);
      ioClient.off("deletedProperty", handleDeleteProperty);
      ioClient.off("createdProperty", handleAddProperty);
    };
  }, []);




  const fetchProperties = async (search, page, sortField, sortOrder) => {
    try {
      const response = await axios.get(
        `https://maliarakis-server.rhodesislandpass.com/property/getPropertiesWithPagination?search=${search}&page=${page}&sortField=${sortField}&sortOrder=${
          sortOrder === 1 ? "asc" : "desc"
        }`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setProperties(response.data.properties);
      setTotalProperties(response.data.totalItems);
      setPageCount(Math.ceil(response.data.totalItems / itemsPerPage));
    } catch (error) {
      console.error("Failed to fetch properties: ", error);
    }
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    setCurrentPage(1); // Reset to first page when search is submitted
    fetchProperties(search, 1, sortField, sortOrder);
  };

  const handleClearSearch = () => {
    setSearch("");
    setCurrentPage(1); // Reset to first page when search is cleared
    fetchProperties("", 1, sortField, sortOrder);
  };

  const handleSort = (field) => {
    if (sortField === field) {
      setSortOrder(sortOrder * -1);
    } else {
      setSortField(field);
      setSortOrder(-1);
    }
  };

  return (
    <>
      {editPopUpOpen && (
        <PopUpWindow
          onClose={() => {
            setEditPopUpOpen(false);
            setEditProperty(null);
          }}
        >
         <AddPropertyForm 
         isEdit={true}
         property={editProperty} />
        </PopUpWindow>
      )}

      {
        viewPopUpOpen && (
          <PopUpWindow
            onClose={() => {
              setViewPopUpOpen(false);
              setEditProperty(null);
            }}
          >
            <ViewProperty property={editProperty} />
          </PopUpWindow>
        )
      }

      {deletePopUpOpen && (
        <PopUpWindow
          onClose={() => {
            setDeletePopUpOpen(false);
            setDeleteProperty(null);
          }}
        >
          <DeleteProperty property={deleteProperty} />
        </PopUpWindow>
      )}

      <Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <form
            onSubmit={handleSearchSubmit}
            style={{
              display: "flex",
              flexDirection: "row",
              width: "95%",
              padding: "10px",
            }}
          >
            <TextField
              variant="outlined"
              placeholder="Search"
              value={search}
              onChange={handleSearchChange}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                endAdornment: search && (
                  <InputAdornment position="end">
                    <Tooltip title="Clear search">
                      <IconButton
                        onClick={handleClearSearch}
                        aria-label="clear search"
                      >
                        <Clear />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
              style={{
                display: "flex",
                flexDirection: "row",
                width: "95%",
                padding: "10px",
              }}
            />
            <Button
              type="submit"
              variant="contained"
              style={{
                marginLeft: "10px",
                padding: "10px",
                width: "20%",
                backgroundColor: "var(--primary-color)",
                color: "var(--primary-text-color)",
                fontSize: "15px",
                fontWeight: "500",
              }}
            >
              Search
            </Button>
          </form>
        </Box>
        <TableContainer
          component={Paper}
          sx={{
            maxheight: 540,
            overflow: "auto",
            // overflowX: "scroll",
            width: "100%",
            paddingRight: "20px",
          }}
        >
          <Table
            aria-label="simple table"
            stickyHeader
            sx={{
              padding: "10px",
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === "name"}
                    direction={sortOrder === 1 ? "asc" : "desc"}
                    onClick={() => handleSort("name")}
                  >
                    Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === "master_lock"}
                    direction={sortOrder === 1 ? "asc" : "desc"}
                    onClick={() => handleSort("master_lock")}
                  >
                    Master Lock
                  </TableSortLabel>
                </TableCell>

                <TableCell>
                  <TableSortLabel
                    active={sortField === "wifi_password"}
                    direction={sortOrder === 1 ? "asc" : "desc"}
                    onClick={() => handleSort("wifi_password")}
                  >
                    Wifi Password
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === "owner_name"}
                    direction={sortOrder === 1 ? "asc" : "desc"}
                    onClick={() => handleSort("owner_name")}
                  >
                    Owner Name
                  </TableSortLabel>
                </TableCell>

                <TableCell>
                  <TableSortLabel
                    active={sortField === "owner_phone"}
                    direction={sortOrder === 1 ? "asc" : "desc"}
                    onClick={() => handleSort("owner_phone")}
                  >
                    Owner Phone
                  </TableSortLabel>
                </TableCell>

                <TableCell>
                  <TableSortLabel
                    active={sortField === "cleaning_name"}
                    direction={sortOrder === 1 ? "asc" : "desc"}
                    onClick={() => handleSort("cleaning_name")}
                  >
                    Cleaning Name
                  </TableSortLabel>
                </TableCell>

                <TableCell>
                  <TableSortLabel
                    Cleaning
                    Phone
                    active={sortField === "cleaning_phone"}
                    direction={sortOrder === 1 ? "asc" : "desc"}
                    onClick={() => handleSort("cleaning_phone")}
                  >
                    Cleaning Phone
                  </TableSortLabel>
                </TableCell>

                {user.permissions.canDeleteProperty && <TableCell></TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {properties.map((property) => (
                <TableRow key={property.id}>
                  <TableCell sx={{ padding: "0px" }}>
                    <Tooltip title="View" placement="right">
                      <Button onClick={() => {
                        setEditProperty(property);
                        setViewPopUpOpen(true);
                      }}>
                        <RemoveRedEye sx={{ color: "var(--primary-color)" }} />
                      </Button>
                    </Tooltip>
                    {user.permissions.canEditProperty && (
                      <Tooltip title="Edit" placement="right">
                        <Button onClick={() => {
                          setEditProperty(property);
                          setEditPopUpOpen(true);
                        }}>
                          <Edit sx={{ color: "var(--primary-color)" }} />
                        </Button>
                      </Tooltip>
                    )}
                  </TableCell>

                  <TableCell>{property.name}</TableCell>
                  <TableCell>{property.master_lock}</TableCell>
                  <TableCell>{property.wifi_password}</TableCell>
                  <TableCell>{property.owner_name}</TableCell>
                  <TableCell>{property.owner_phone}</TableCell>
                  <TableCell>{property.cleaning_name}</TableCell>
                  <TableCell>{property.cleaning_phone}</TableCell>

                  {(user.permissions.canEditProperty ||
                    user.permissions.canDeleteProperty) && (
                    <TableCell>
                      {user.permissions.canDeleteProperty && (
                        <Tooltip title="Delete" color="primary">
                          <IconButton
                            onClick={() => {
                              setDeleteProperty(property);
                              setDeletePopUpOpen(true);
                            }}
                          >
                            <Delete sx={{ color: "var(--primary-color)" }} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          m={2}
          sx={{ width: "95%" }}
        >
          <Typography>
            Showing {(currentPage - 1) * itemsPerPage}-
            {Math.min(currentPage * itemsPerPage, totalProperties)} of{" "}
            {totalProperties} items
          </Typography>
          <Pagination
            count={pageCount}
            page={currentPage}
            onChange={(event, page) => {
              setCurrentPage(page);
            }}
            color="primary"
          />
        </Box>
      </Box>
    </>
  );
};

export default PropertiesTable;
