import React, { useState, useEffect, useRef } from "react";

const StyledTextarea = ({ users, onCommentChange, reset }) => {
  const editableDivRef = useRef(null);
  const [content, setContent] = useState("");

  const dropdownRef = useRef(null);

  const [showDropdown, setShowDropdown] = useState(false);
  const [filteredUsernames, setFilteredUsernames] = useState([]);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !editableDivRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, []);

  const notifyChange = () => {
    const currentText = editableDivRef.current.innerText;
    onCommentChange(currentText);
  };
  // Listen for changes in the contentEditable div
  const updateDropdownPosition = () => {
    if (document.getSelection().rangeCount > 0) {
      const range = document.getSelection().getRangeAt(0);
      const rect = range.getBoundingClientRect();

      if (rect.top && rect.left) {
        // Checks if top and left are non-zero
        setDropdownPosition({
          top: rect.top + window.scrollY + rect.height,
          left: rect.left + window.scrollX,
        });
      } else {
        // Fallback position or another strategy to place the dropdown
      }
    }
  };

  const selectUsername = (username) => {
    const currentText = editableDivRef.current.innerText;
    const newText = currentText.replace(/@(\w*)$/, `@${username}  `);
    setContent(newText);

    editableDivRef.current.innerText = newText;
    setShowDropdown(false);
    placeCaretAtEnd(editableDivRef.current);
  };

  // A function to generate HTML with styled usernames
  const generateStyledContent = (text) => {
    // Escape HTML to prevent XSS attacks
    let escapedText = text
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
    const usernamePattern = /@(\w+)/g;
    // Replace usernames with styled spans
    return escapedText.replace(usernamePattern, (match, p1) => {
      if (users.some((user) => user.username === p1)) {
        return `<span style="background-color: #b5c2cf; color: #45505a; text-decoration: underline;">${match}</span>`;
      }
      return match;
    });
  };

  useEffect(() => {
    setContent("");
    editableDivRef.current.innerText = "";
  }, [reset]);
  // Update the styled content whenever the text content changes
  useEffect(() => {
    const currentContent = editableDivRef.current.innerText;
    if (content !== currentContent) {
      setContent(currentContent);
    }
  }, [content]);

  const handleInput = (event) => {
    const currentText = editableDivRef.current.innerText;
    setContent(currentText);
    notifyChange(); // Notify parent component of the change

    // Check for user mentions pattern
    const match = /(?<!\w)@(\w*)$/.exec(currentText);
    if (match) {
      const search = match[1];
      const filtered = users.filter((user) =>
        user.username.toLowerCase().startsWith(search.toLowerCase())
      );
      setFilteredUsernames(filtered);
      setShowDropdown(true);
      // Update dropdown position
      updateDropdownPosition();
    } else {
      setShowDropdown(false);
    }

    if (match) {
      const search = match[1];
      const filtered = users.filter((user) =>
        user.username.toLowerCase().startsWith(search.toLowerCase())
      );
      setFilteredUsernames(filtered);
      setShowDropdown(true);

      // Update dropdown position
      updateDropdownPosition();
    } else {
      setShowDropdown(false);
    }

    // Generate and set styled content
    const newHTML = generateStyledContent(currentText);
    if (editableDivRef.current.innerHTML !== newHTML) {
      // Temporarily disable input event listener to prevent an infinite loop
      editableDivRef.current.removeEventListener("input", handleInput);
      editableDivRef.current.innerHTML = newHTML;
      // Re-enable input event listener
      editableDivRef.current.addEventListener("input", handleInput);

      // Move the cursor to the end; refine for better cursor management
      placeCaretAtEnd(editableDivRef.current);
    }
  };

  // Function to place the caret at the end of the contentEditable element
  function placeCaretAtEnd(el) {
    el.focus();
    if (
      typeof window.getSelection != "undefined" &&
      typeof document.createRange != "undefined"
    ) {
      var range = document.createRange();
      range.selectNodeContents(el);
      range.collapse(false);
      var sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    }
  }

  return (
    <>
      <div
        contentEditable
        ref={editableDivRef}
        onInput={handleInput}
        onBlur={handleInput} // Re-parse when the user clicks away
        style={{
          minHeight: "100px",
          border: "1px solid grey",
          padding: "10px",
          margin: "10px",
          overflowY: "auto",
          width: "100%",
          backgroundColor: "#45505a",
          color: "#b5c2cf",
        }}
      ></div>
      {showDropdown && (
        <ul
          ref={dropdownRef}
          style={{
            position: "absolute",
            top: `${dropdownPosition.top}px`,
            left: `${dropdownPosition.left}px`,
            backgroundColor: "white",
            color: "black",
            listStyleType: "none",
            padding: "5px",
            border: "1px solid #ccc",
            borderRadius: "5px",
            zIndex: 1000,

            // Optional styles
          }}
        >
          {filteredUsernames.map((user) => (
            <li
              key={user.username}
              style={{ padding: "2px 5px", cursor: "pointer" }}
              onClick={() => selectUsername(user.username)}
            >
              {user.username}
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default StyledTextarea;
