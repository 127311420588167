import React, { useState, useEffect } from "react";

import { TextField, Alert } from "@mui/material";
import { Add, Edit } from "@mui/icons-material";

import axios from "axios";

const AddPropertyForm = (props) => {
  const [propertyName, setPropertyName] = useState(
    
    !props.property?"":  props.property.name);
  const [owner_name, setOwnerName] = useState(!props.property?"":  props.property.owner_name);
  const [owner_email, setOwnerEmail] = useState(!props.property?"":  props.property.owner_email);
  const [owner_phone, setOwnerPhone] = useState(!props.property?"":  props.property.owner_phone);
  const [owner_phone2, setOwnerPhone2] = useState(!props.property?"":  props.property.owner_phone2);
  const [owner_home_number, setOwnerHomeNumber] = useState(
    !props.property?"":  props.property.owner_home_number
  );
  const [logistic_name, setLogisticName] = useState(!props.property?"":  props.property.logistic_name);
  const [logistic_phone, setLogisticPhone] = useState(!props.property?"":  props.property.logistic_phone);
  const [logistic_email, setLogisticEmail] = useState(!props.property?"":  props.property.logistic_email);
  const [logistic_phone2, setLogisticPhone2] = useState(!props.property?"":  props.property.logistic_phone2);
  const [cleaning_name, setCleaningName] = useState(!props.property?"":  props.property.cleaning_name);
  const [cleaning_phone, setCleaningPhone] = useState(!props.property?"":  props.property.cleaning_phone);
  const [cleaning_email, setCleaningEmail] = useState(!props.property?"":  props.property.cleaning_email);
  const [cleaning_phone2, setCleaningPhone2] = useState(!props.property?"":  props.property.cleaning_phone2);
  const [maintenance_name, setMaintenanceName] = useState(
    !props.property?"":  props.property.maintenance_name
  );
  const [maintenance_email, setMaintenanceEmail] = useState(
    !props.property?"":  props.property.maintenance_email
  );
  const [maintenance_phone, setMaintenancePhone] = useState(
    !props.property?"":  props.property.maintenance_phone
  );
  const [maintenance_phone2, setMaintenancePhone2] = useState(
    !props.property?"":  props.property.maintenance_phone2
  );
  const [pool_name, setPoolName] = useState(!props.property?"":  props.property.pool_name);
  const [pool_phone, setPoolPhone] = useState(!props.property?"":  props.property.pool_phone);
  const [pool_email, setPoolEmail] = useState(!props.property?"":  props.property.pool_email);
  const [pool_phone2, setPoolPhone2] = useState(!props.property?"":  props.property.pool_phone2);
  const [property_location, setPropertyLocation] = useState(
    !props.property?"":  props.property.property_location
  );
  const [master_lock, setMasterLock] = useState(!props.property?"":  props.property.master_lock);
  const [wifi_username, setWifiUsername] = useState(!props.property?"":  props.property.wifi_username);
  const [wifi_password, setWifiPassword] = useState(!props.property?"":  props.property.wifi_password);
  const [manual_link, setManualLink] = useState(!props.property?"":  props.property.manual_link);
  const [arrivals, setArrivals] = useState(!props.property?"":  props.property.arrivals);
  const [more_info, setMoreInfo] = useState(!props.property?"":  props.property.more_info ? props.property.more_info : "");

  const setIsOpen = props.setIsOpen;
  const isEdit = props.isEdit;

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const user = JSON.parse(localStorage.getItem("user"));

  const handleAddProperty = async (property) => {
    try {
      const response = await axios.post(
        "https://maliarakis-server.rhodesislandpass.com/property/createProperty",
        {
          title: property.title,
          owner_name: property.owner_name,
          owner_email: property.owner_email,
          owner_phone: property.owner_phone,
          owner_phone2: property.owner_phone2,
          owner_home_number: property.owner_home_number,
          logistic_name: property.logistic_name,
          logistic_phone: property.logistic_phone,
          logistic_email: property.logistic_email,
          logistic_phone2: property.logistic_phone2,
          cleaning_name: property.cleaning_name,
          cleaning_phone: property.cleaning_phone,
          cleaning_email: property.cleaning_email,
          cleaning_phone2: property.cleaning_phone2,
          maintenance_name: property.maintenance_name,
          maintenance_email: property.maintenance_email,
          maintenance_phone: property.maintenance_phone,
          maintenance_phone2: property.maintenance_phone2,
          pool_name: property.pool_name,
          pool_phone: property.pool_phone,
          pool_email: property.pool_email,
          pool_phone2: property.pool_phone2,
          property_location: property.property_location,
          master_lock: property.master_lock,
          wifi_username: property.wifi_username,
          wifi_password: property.wifi_password,
          manual_link: property.manual_link,
          arrivals: property.arrivals,
          more_info: property.more_info,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setSuccess(
        isEdit ? "Property edited successfully" : "Property added successfully"
      );
    } catch (error) {
      setError("Error adding property");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const property = {
      title: propertyName,
      owner_name: owner_name,
      owner_email: owner_email,
      owner_phone: owner_phone,
      owner_phone2: owner_phone2,
      owner_home_number: owner_home_number,
      logistic_name: logistic_name,
      logistic_phone: logistic_phone,
      logistic_email: logistic_email,
      logistic_phone2: logistic_phone2,
      cleaning_name: cleaning_name,
      cleaning_phone: cleaning_phone,
      cleaning_email: cleaning_email,
      cleaning_phone2: cleaning_phone2,
      maintenance_name: maintenance_name,
      maintenance_email: maintenance_email,
      maintenance_phone: maintenance_phone,
      maintenance_phone2: maintenance_phone2,
      pool_name: pool_name,
      pool_phone: pool_phone,
      pool_email: pool_email,
      pool_phone2: pool_phone2,
      property_location: property_location,
      master_lock: master_lock,
      wifi_username: wifi_username,
      wifi_password: wifi_password,
      manual_link: manual_link,
      arrivals: arrivals,
      more_info: more_info,
    };

    handleAddProperty(property);
  };

  return (
    <>
      <form className="ticket-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <div className="form-field">
            <label className="form-field-label" htmlFor="title">
              Title*:
            </label>

            <input
              className="form-field-input"
              type="text"
              id="title"
              name="title"
              value={propertyName}
              onChange={(e) => setPropertyName(e.target.value)}
              required
            />
          </div>
        </div>

        <hr
          style={{
            width: "80%",
            border: "1px solid var(--primary-color)",
          }}
        />
        <div className="form-group">
          <div className="form-field">
            <label className="form-field-label" htmlFor="owner_name">
              Owner Name:
            </label>

            <input
              className="form-field-input"
              type="text"
              id="owner_name"
              name="owner_name"
              value={owner_name}
              onChange={(e) => setOwnerName(e.target.value)}
            />
          </div>

          <div className="form-field">
            <label className="form-field-label" htmlFor="owner_email">
              Owner Email:
            </label>

            <input
              className="form-field-input"
              type="text"
              id="owner_email"
              name="owner_email"
              value={owner_email}
              onChange={(e) => setOwnerEmail(e.target.value)}
            />
          </div>
        </div>

        <div className="form-group">
          <div className="form-field">
            <label className="form-field-label" htmlFor="owner_phone">
              Owner Mobile Phone:
            </label>

            <input
              className="form-field-input"
              type="text"
              id="owner_phone"
              name="owner_phone"
              value={owner_phone}
              onChange={(e) => setOwnerPhone(e.target.value)}
            />
          </div>

          <div className="form-field">
            <label className="form-field-label" htmlFor="owner_phone2">
              Owner Mobile Phone 2:
            </label>

            <input
              className="form-field-input"
              type="text"
              id="owner_phone2"
              name="owner_phone2"
              value={owner_phone2}
              onChange={(e) => setOwnerPhone2(e.target.value)}
            />
          </div>

          <div className="form-field">
            <label className="form-field-label" htmlFor="owner_home_number">
              Owner Home Number:
            </label>

            <input
              className="form-field-input"
              type="text"
              id="owner_home_number"
              name="owner_home_number"
              value={owner_home_number}
              onChange={(e) => setOwnerHomeNumber(e.target.value)}
            />
          </div>
        </div>
        <hr
          style={{
            width: "80%",
            border: "1px solid var(--primary-color)",
          }}
        />
        <div className="form-group">
          <div className="form-field">
            <label className="form-field-label" htmlFor="logistic_name">
            Accounting Name:
            </label>

            <input
              className="form-field-input"
              type="text"
              id="logistic_name"
              name="logistic_name"
              value={logistic_name}
              onChange={(e) => setLogisticName(e.target.value)}
            />
          </div>

          <div className="form-field">
            <label className="form-field-label" htmlFor="logistic_email">
            Accounting Email:
            </label>

            <input
              className="form-field-input"
              type="text"
              id="logistic_email"
              name="logistic_email"
              value={logistic_email}
              onChange={(e) => setLogisticEmail(e.target.value)}
            />
          </div>
        </div>
        <div className="form-group">
          <div className="form-field">
            <label className="form-field-label" htmlFor="logistic_phone">
            Accounting Phone:
            </label>

            <input
              className="form-field-input"
              type="text"
              id="logistic_phone"
              name="logistic_phone"
              value={logistic_phone}
              onChange={(e) => setLogisticPhone(e.target.value)}
            />
          </div>

          <div className="form-field">
            <label className="form-field-label" htmlFor="logistic_phone2">
              Accounting Phone 2:
            </label>

            <input
              className="form-field-input"
              type="text"
              id="logistic_phone2"
              name="logistic_phone2"
              value={logistic_phone2}
              onChange={(e) => setLogisticPhone2(e.target.value)}
            />
          </div>
        </div>
        <hr
          style={{
            width: "80%",
            border: "1px solid var(--primary-color)",
          }}
        />
        <div className="form-group">
          <div className="form-field">
            <label className="form-field-label" htmlFor="cleaning_name">
              Cleaning Name:
            </label>

            <input
              className="form-field-input"
              type="text"
              id="cleaning_name"
              name="cleaning_name"
              value={cleaning_name}
              onChange={(e) => setCleaningName(e.target.value)}
            />
          </div>

          <div className="form-field">
            <label className="form-field-label" htmlFor="cleaning_email">
              Cleaning Email:
            </label>

            <input
              className="form-field-input"
              type="text"
              id="cleaning_email"
              name="cleaning_email"
              value={cleaning_email}
              onChange={(e) => setCleaningEmail(e.target.value)}
            />
          </div>
        </div>
        <div className="form-group">
          <div className="form-field">
            <label className="form-field-label" htmlFor="cleaning_phone">
              Cleaning Phone:
            </label>

            <input
              className="form-field-input"
              type="text"
              id="cleaning_phone"
              name="cleaning_phone"
              value={cleaning_phone}
              onChange={(e) => setCleaningPhone(e.target.value)}
            />
          </div>

          <div className="form-field">
            <label className="form-field-label" htmlFor="cleaning_phone2">
              Cleaning Phone 2:
            </label>

            <input
              className="form-field-input"
              type="text"
              id="cleaning_phone2"
              name="cleaning_phone2"
              value={cleaning_phone2}
              onChange={(e) => setCleaningPhone2(e.target.value)}
            />
          </div>
        </div>
        <hr
          style={{
            width: "80%",
            border: "1px solid var(--primary-color)",
          }}
        />
        <div className="form-group">
          <div className="form-field">
            <label className="form-field-label" htmlFor="maintenance_name">
              Maintenance Name:
            </label>

            <input
              className="form-field-input"
              type="text"
              id="maintenance_name"
              name="maintenance_name"
              value={maintenance_name}
              onChange={(e) => setMaintenanceName(e.target.value)}
            />
          </div>

          <div className="form-field">
            <label className="form-field-label" htmlFor="maintenance_email">
              Maintenance Email:
            </label>

            <input
              className="form-field-input"
              type="text"
              id="maintenance_email"
              name="maintenance_email"
              value={maintenance_email}
              onChange={(e) => setMaintenanceEmail(e.target.value)}
            />
          </div>
        </div>

        <div className="form-group">
          <div className="form-field">
            <label className="form-field-label" htmlFor="maintenance_phone">
              Maintenance Phone:
            </label>

            <input
              className="form-field-input"
              type="text"
              id="maintenance_phone"
              name="maintenance_phone"
              value={maintenance_phone}
              onChange={(e) => setMaintenancePhone(e.target.value)}
            />
          </div>

          <div className="form-field">
            <label className="form-field-label" htmlFor="maintenance_phone2">
              Maintenance Phone 2:
            </label>

            <input
              className="form-field-input"
              type="text"
              id="maintenance_phone2"
              name="maintenance_phone2"
              value={maintenance_phone2}
              onChange={(e) => setMaintenancePhone2(e.target.value)}
            />
          </div>
        </div>
        <hr
          style={{
            width: "80%",
            border: "1px solid var(--primary-color)",
          }}
        />

        <div className="form-group">
          <div className="form-field">
            <label className="form-field-label" htmlFor="pool_name">
              Pool Name:
            </label>

            <input
              className="form-field-input"
              type="text"
              id="pool_name"
              name="pool_name"
              value={pool_name}
              onChange={(e) => setPoolName(e.target.value)}
            />
          </div>

          <div className="form-field">
            <label className="form-field-label" htmlFor="pool_email">
              Pool Email:
            </label>

            <input
              className="form-field-input"
              type="text"
              id="pool_email"
              name="pool_email"
              value={pool_email}
              onChange={(e) => setPoolEmail(e.target.value)}
            />
          </div>
        </div>
        <div className="form-group">
          <div className="form-field">
            <label className="form-field-label" htmlFor="pool_phone">
              Pool Phone:
            </label>

            <input
              className="form-field-input"
              type="text"
              id="pool_phone"
              name="pool_phone"
              value={pool_phone}
              onChange={(e) => setPoolPhone(e.target.value)}
            />
          </div>

          <div className="form-field">
            <label className="form-field-label" htmlFor="pool_phone2">
              Pool Phone 2:
            </label>

            <input
              className="form-field-input"
              type="text"
              id="pool_phone2"
              name="pool_phone2"
              value={pool_phone2}
              onChange={(e) => setPoolPhone2(e.target.value)}
            />
          </div>
        </div>

        <hr
          style={{
            width: "80%",
            border: "1px solid var(--primary-color)",
          }}
        />

        <div className="form-group">
          <div className="form-field">
            <label className="form-field-label" htmlFor="property_location">
              Property Location:
            </label>

            <input
              className="form-field-input"
              type="text"
              id="property_location"
              name="property_location"
              value={property_location}
              onChange={(e) => setPropertyLocation(e.target.value)}
            />
          </div>

          <div className="form-field">
            <label className="form-field-label" htmlFor="master_lock">
              Master Lock:
            </label>

            <input
              className="form-field-input"
              type="text"
              id="master_lock"
              name="master_lock"
              value={master_lock}
              onChange={(e) => setMasterLock(e.target.value)}
            />
          </div>
        </div>

        <div className="form-group">
          <div className="form-field">
            <label className="form-field-label" htmlFor="wifi_username">
              Wifi Username:
            </label>

            <input
              className="form-field-input"
              type="text"
              id="wifi_username"
              name="wifi_username"
              value={wifi_username}
              onChange={(e) => setWifiUsername(e.target.value)}
            />
          </div>

          <div className="form-field">
            <label className="form-field-label" htmlFor="wifi_password">
              Wifi Password:
            </label>

            <input
              className="form-field-input"
              type="text"
              id="wifi_password"
              name="wifi_password"
              value={wifi_password}
              onChange={(e) => setWifiPassword(e.target.value)}
            />
          </div>
        </div>

        <div className="form-group">
          <div className="form-field">
            <label className="form-field-label" htmlFor="manual_link">
              Manual Link:
            </label>

            <input
              className="form-field-input"
              type="text"
              id="manual_link"
              name="manual_link"
              value={manual_link}
              onChange={(e) => setManualLink(e.target.value)}
            />
          </div>

          <div className="form-field">
            <label className="form-field-label" htmlFor="arrivals">
              Arrivals:
            </label>

            <input
              className="form-field-input"
              type="text"
              id="arrivals"
              name="arrivals"
              value={arrivals}
              onChange={(e) => setArrivals(e.target.value)}
            />
          </div>
        </div>

        <div className="form-group">
          <div className="form-field">
            <label className="form-field-label" htmlFor="more_info">
              More Info:
            </label>

            <textarea
              className="form-field-input"
              type="text-area"
              id="more_info"
              name="more_info"
              value={more_info}
              onChange={(e) => setMoreInfo(e.target.value)}
            />
          </div>
        </div>

        <hr
          style={{
            width: "80%",
            border: "1px solid var(--primary-color)",
          }}
        />
        {error && <Alert severity="error">{error}</Alert>}  
        {success && <Alert severity="success">{success}</Alert>}

        <div className="form-group">
          <div className="form-field">
            <button type="submit" className="submit-button">
              {isEdit ? "Edit Property" : "Add Property"}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddPropertyForm;
