import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableSortLabel,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  Button,
  Box,
  Pagination,
  Typography,
  InputAdornment,
  IconButton,
  TextField,
} from "@mui/material";
import { RemoveRedEye, Edit, Search, Clear } from "@mui/icons-material/";

import { Loader } from "../../components";
import io from "socket.io-client";

function formatDate(date) {
  date = new Date(date);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

const socket = io("https://maliarakis-server.rhodesislandpass.com/");

function ReservationTable() {
  const user = JSON.parse(localStorage.getItem("user"));
  const [reservations, setReservations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalReservations, setTotalReservations] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [checkIn, setCheckIn] = useState("");
  const [checkOut, setCheckOut] = useState("");
  const [sortField, setSortField] = useState("updatedAt");
  const [sortOrder, setSortOrder] = useState(-1); // -1 for descending, 1 for ascending

  useEffect(() => {
    const handleReservationCreate = (reservation) => {
        setTotalReservations((prevTotalReservations) => {
          const newTotal = prevTotalReservations + 1;
          
          // Calculate new page count based on the updated total reservations
          setPageCount(Math.ceil(newTotal / itemsPerPage));
          
          return newTotal;
        });
    
        if (currentPage === 1) {
          setReservations((prevReservations) => {
            if (prevReservations.length === itemsPerPage) {
              // If the page is full, remove the last item and add the new reservation at the start
              return [reservation, ...prevReservations.slice(0, -1)];
            } else {
              // If the page is not full, just add the new reservation at the start
              return [reservation, ...prevReservations];
            }
          });
        }
      };

    const handleReservationUpdate = (updatedReservation) => {
      // Update the reservation in the state
      setReservations((prevReservations) =>
        prevReservations.map((reservation) =>
          reservation._id === updatedReservation._id
            ? updatedReservation
            : reservation
        )
      );
    };

    const handleReservationDelete = (reservationId) => {
      // Remove the deleted reservation from the state
      setReservations((prevReservations) =>
        prevReservations.filter(
          (reservation) => reservation._id !== reservationId
        )
      );
      setTotalReservations(
        (prevTotalReservations) => prevTotalReservations - 1
      );
      setPageCount((prevPageCount) =>
        Math.ceil((totalReservations - 1) / itemsPerPage)
      );
    };

    socket.on("reservationCreated", handleReservationCreate);
    socket.on("reservationUpdated", handleReservationUpdate);
    socket.on("reservationDeleted", (reservationId) =>
      handleReservationDelete(reservationId)
    );

    return () => {
      socket.off("reservationCreated", handleReservationCreate);
      socket.off("reservationUpdated", handleReservationUpdate);
      socket.off("reservationDeleted", handleReservationDelete);
    };
  }, []);

  useEffect(() => {
    setIsLoading(true);
    fetchReservations(searchTerm, checkIn, checkOut);
    setIsLoading(false);
  }, [currentPage, itemsPerPage, sortField, sortOrder]);

  const fetchReservations = async (term, checkIn, checkOut) => {
    setIsLoading(true);

    try {
      const response = await axios.get(
        `https://maliarakis-server.rhodesislandpass.com/reservation/getReservations?page=${currentPage}&itemsPerPage=${itemsPerPage}&search=${term}&checkIn=${checkIn}&checkOut=${checkOut}&sortField=${sortField}&sortOrder=${
          sortOrder === 1 ? "asc" : "desc"
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setReservations([...response.data.reservations]);
      setTotalReservations(response.data.totalItems);
      setPageCount(Math.ceil(response.data.totalItems / itemsPerPage));
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault(); // Prevent the form from causing a page reload
    fetchReservations(searchTerm, checkIn, checkOut);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleClearSearch = (event) => {
    event.preventDefault(); // Prevent the default form submit behavior
    event.stopPropagation(); // Stop the event from propagating to the form's onSubmit handler
    setSearchTerm("");
    setCheckIn("");
    setCheckOut("");
    setSortField("updatedAt");
    setSortOrder(-1);
    setCurrentPage(1);

    fetchReservations("", "", "");
  };

  const handleSortChange = (field) => {
    if (sortField === field) {
      // Toggle sort order if the same field was clicked
      setSortOrder(sortOrder * -1);
    } else {
      // Set new field and default to ascending
      setSortField(field);
      setSortOrder(1);
    }
  };

  return (
    <>
      {isLoading && <Loader />}

      {!isLoading && (
        <>
          <form
            onSubmit={handleSearchSubmit}
            style={{
              display: "flex",
              flexDirection: "row",
              width: "95%",
              padding: "10px",
            }}
          >
            <TextField
              variant="outlined"
              placeholder="Search reservation, person or property"
              value={searchTerm}
              onChange={handleSearchChange}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                endAdornment: searchTerm && (
                  <InputAdornment position="end">
                    <Tooltip title="Clear search">
                      <IconButton
                        onClick={() => {
                          setSearchTerm("");
                          fetchReservations("", checkIn, checkOut);
                        }}
                        aria-label="clear search"
                      >
                        <Clear />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              label="Check-In Date"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={checkIn}
              onChange={(e) => setCheckIn(e.target.value)}
              sx={{ marginLeft: "10px", width: "20%" }}
            />
            <TextField
              label="Check-Out Date"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={checkOut}
              onChange={(e) => setCheckOut(e.target.value)}
              sx={{ marginLeft: "10px", width: "20%" }}
            />

            <Button
              type="submit"
              variant="contained"
              
              style={{
                marginLeft: "10px",
                padding: "10px",
                width: "20%",
                backgroundColor:  "var(--primary-color)",
                color:  "var(--primary-text-color)",
                fontSize:  "15px",
                fontWeight: "500",
              }}
            >
              Search
            </Button>

            <Button
              variant="contained"
              color="secondary"
              style={{
                marginLeft: "10px",
                padding: "10px",
                width: "20%",
                backgroundColor:  "var(--secondary-color)",
                color:  "var(--primary-text-color)",
                fontSize:  "15px",
                fontWeight: "500",
              }}
              onClick={handleClearSearch}
            >
              Clear
            </Button>
          </form>
          <TableContainer
            component={Paper}
            sx={{
              maxHeight: 540,
              overflow: "auto",
              width: "95%",
              paddingRight: "20px",
            }}
          >
            <Table aria-label="simple table" stickyHeader>
              <TableHead>
                <TableRow>
                  {/* <TableCell></TableCell> */}
                  <TableCell align="center" key="reservationId">
                    <TableSortLabel
                      active={sortField === "reservationId"}
                      direction={
                        sortField === "reservationId" && sortOrder === 1
                          ? "asc"
                          : "desc"
                      }
                      onClick={() => handleSortChange("reservationId")}
                    >
                      Reservation ID
                    </TableSortLabel>
                  </TableCell>

                  <TableCell align="center" key="property">
                    <TableSortLabel
                      active={sortField === "property"}
                      direction={
                        sortField === "property" && sortOrder === 1
                          ? "asc"
                          : "desc"
                      }
                      onClick={() => handleSortChange("property")}
                    >
                      Property
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center" key="checkIn">
                    <TableSortLabel
                      active={sortField === "checkIn"}
                      direction={
                        sortField === "checkIn" && sortOrder === 1
                          ? "asc"
                          : "desc"
                      }
                      onClick={() => handleSortChange("checkIn")}
                    >
                      Check-In
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center" key="checkOut">
                    <TableSortLabel
                      active={sortField === "checkOut"}
                      direction={
                        sortField === "checkOut" && sortOrder === 1
                          ? "asc"
                          : "desc"
                      }
                      onClick={() => handleSortChange("checkOut")}
                    >
                      Check-Out
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center" key="nights">
                    <TableSortLabel
                      active={sortField === "nights"}
                      direction={
                        sortField === "nights" && sortOrder === 1
                          ? "asc"
                          : "desc"
                      }
                      onClick={() => handleSortChange("nights")}
                    >
                      Nights
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center" key="guests">
                    Guests
                  </TableCell>
                  <TableCell align="center" key="firstName">
                    <TableSortLabel
                      active={sortField === "firstName"}
                      direction={
                        sortField === "firstName" && sortOrder === 1
                          ? "asc"
                          : "desc"
                      }
                      onClick={() => handleSortChange("firstName")}
                    >
                      First Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center" key="lastName">
                    <TableSortLabel
                      active={sortField === "lastName"}
                      direction={
                        sortField === "lastName" && sortOrder === 1
                          ? "asc"
                          : "desc"
                      }
                      onClick={() => handleSortChange("lastName")}
                    >
                      Last Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center" key="phone">
                    <TableSortLabel
                      active={sortField === "phone"}
                      direction={
                        sortField === "phone" && sortOrder === 1
                          ? "asc"
                          : "desc"
                      }
                      onClick={() => handleSortChange("phone")}
                    >
                      Phone
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reservations.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    {/* <TableCell sx={{ padding: "0px" }}>
                      <Tooltip title="View">
                        <Button>
                          <RemoveRedEye
                            sx={{ color: "var(--primary-color)" }}
                          />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Edit">
                        <Button>
                          <Edit sx={{ color: "var(--primary-color)" }} />
                        </Button>
                      </Tooltip>
                    </TableCell> */}
                    <TableCell align="center" component="th" scope="row">
                      {row.reservationId}
                    </TableCell>
                    <TableCell align="center">{row.property}</TableCell>
                    <TableCell align="center">
                      {formatDate(row.checkIn)}
                    </TableCell>
                    <TableCell align="center">
                      {formatDate(row.checkOut)}
                    </TableCell>
                    <TableCell align="center">{row.nights}</TableCell>
                    <TableCell align="center">
                      {row.adults + row.kids}
                    </TableCell>
                    <TableCell align="center">{row.firstName}</TableCell>
                    <TableCell align="center">{row.lastName}</TableCell>
                    <TableCell align="center">{row.phone}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            m={2}
            sx={{ width: "95%" }}
          >
            <Typography>
              Showing{" "}
              {Math.min(
                (currentPage - 1) * itemsPerPage + 1,
                totalReservations
              )}
              -{Math.min(currentPage * itemsPerPage, totalReservations)} of{" "}
              {totalReservations} items
            </Typography>
            <Pagination
              count={pageCount}
              page={currentPage}
              onChange={(event, page) => {
                setCurrentPage(page);
              }}
              color="primary"
            />
          </Box>
        </>
      )}
    </>
  );
}

export default ReservationTable;
