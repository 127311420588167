import React, { useState, useEffect } from "react";
import { Upload } from "@mui/icons-material";
import axios from "axios";

import * as XLSX from "xlsx";

import "./ImportPropertyButton.css";
import { upload } from "@testing-library/user-event/dist/upload";

const ImportPropertyButton = (props) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [buttonWidth, setButtonWidth] = useState(
    window.innerWidth <= 768 ? "100%" : "auto"
  );
  const user = JSON.parse(localStorage.getItem("user"));

  const BATCH_SIZE = 100;
  const handleFile = async (e) => {
    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data, { type: "buffer" });
    const worksheetName = workbook.SheetNames[1];
    const worksheet = workbook.Sheets[worksheetName];
    const jsonData = XLSX.utils.sheet_to_json(worksheet);
    setData(jsonData);


   
    props.setIsLoading && props.setIsLoading(true);
    // Batch upload logic
    for (let i = 0; i < jsonData.length; i += BATCH_SIZE) {
      const batch = jsonData.slice(i, i + BATCH_SIZE);
      await Promise.all(
        batch.map((property) =>
         uploadProperty (mapProperty(property))
        )
      );
    }

   

    props.setIsLoading && props.setIsLoading(false);
  };

  const mapProperty = (property) => ({
    title: property["Όνομα Καταλύματος"],
    owner_name : property["Όνομα Ιδιοκτήτη "],
    owner_email : property["E-mail Ιδιοκτήτη"],
    owner_phone : property["Κινητό Ιδιοκτήτη"],
    owner_phone2 : property["Κινητό Ιδιοκτήτη ΙΙ"],
    owner_home_number : property["Σταθερό Ιδιοκτήτη"],
    logistic_name : property["Λογιστικά"],
    logistic_phone : property["Λογιστής Τηλέφωνο Ι"],
    logistic_email : property["E-mail Λογιστή"],
    logistic_phone2 : property["Λογιστής Τηλέφωνο ΙΙ"],
    cleaning_name : property["Καθαρισμός"],
    cleaning_phone : property["Τηλέφωνο Καθαρισμού"],
    cleaning_email : property["E-mail Καθαρισμού"],
    cleaning_phone2 : property["Τηλέφωνο Καθαρισμού ΙΙ"],
    maintenance_name : property["Συντήρηση"],
    maintenance_email : property["E-mail Συντήρησης "],
    maintenance_phone : property["Τηλέφωνο Συντήρησης"],
    maintenance_phone2 : property["Τηλέφωνο Συντήρησης ΙΙ"],
    pool_name : property["Πισίνα"],
    pool_phone : property["Τηλέφωνο Πισίνας"],
    pool_email : property["E-mail Πισίνας"],
    pool_phone2 : property["Τηλέφωνο Πισίνας ΙΙ"],
    property_location : property["Τοποθεσία Καταλύματος"],
    master_lock : property["Master Lock "],
    wifi_username : property["WiFi username"],
    wifi_password : property["WiFi password"],
    manual_link : property["Manual Link"],
    arrivals : property["Αφίξεις "],
  });

  const uploadProperty = async (property) => {
    try {
      const response = await axios.post(
        "https://maliarakis-server.rhodesislandpass.com/property/createProperty",
        {
          title: property.title,
          owner_name : property.owner_name,
          owner_email : property.owner_email,
          owner_phone : property.owner_phone,
          owner_phone2 : property.owner_phone2,
          owner_home_number : property.owner_home_number,
          logistic_name : property.logistic_name,
          logistic_phone : property.logistic_phone,
          logistic_email : property.logistic_email,
          logistic_phone2 : property.logistic_phone2,
          cleaning_name : property.cleaning_name,
          cleaning_phone : property.cleaning_phone,
          cleaning_email : property.cleaning_email,
          cleaning_phone2 : property.cleaning_phone2,
          maintenance_name : property.maintenance_name,
          maintenance_email : property.maintenance_email,
          maintenance_phone : property.maintenance_phone,
          maintenance_phone2 : property.maintenance_phone2,
          pool_name : property.pool_name,
          pool_phone : property.pool_phone,
          pool_email : property.pool_email,
          pool_phone2 : property.pool_phone2,
          property_location : property.property_location,
          master_lock : property.master_lock,
          wifi_username : property.wifi_username,
          wifi_password : property.wifi_password,
          manual_link : property.manual_link,
          arrivals : property.arrivals,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

    } catch (error) {

    }
  };


  const handleClick = () => {
    props.setIsLoading && props.setIsLoading(true);
    const input = document.createElement("input");
    input.type = "file";
    input.accept =
      ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel";
    input.onchange = handleFile;
    input.click();
  };

  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth <= 768 ? "100%" : "auto";
      setButtonWidth(newWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div
        className="add-ticket-button"
        style={{
          display: user.permissions.canAddReservation ? "flex" : "none",
        }}
      >
        <button
          className="add-ticket-button__button"
          style={{
            backgroundColor: props.color || "var(--primary-color)",
            color: props.textColor || "var(--primary-text-color)",
            fontSize: props.fontSize || "15px",
            fontWeight: props.fontWeight || "600",
            width: props.width || buttonWidth,
            marginLeft: props.marginLeft || "0px",
            marginRight: props.marginRight || "0px",
          }}
          onClick={handleClick}
        >
          <Upload />
          <span>Import Properties</span>
        </button>
      </div>
    </>
  );
};
export default ImportPropertyButton;








