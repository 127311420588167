import React, { useState, useEffect, useRef } from "react";
import { useUser } from "../../contexts/UserContext";

import "./Dashboard.css";
import {
  AddReservationButton,
  AddTicketButton,
  Chatrooms,
  CreateChatroomButton,
  Sidebar,
  TaskManager,
  TicketTable,
} from "../../components";
import { PageStructure } from "../../pages";
import { Add, Task } from "@mui/icons-material";
import { Button } from "@mui/material";

const Dashboard = () => {
  const assignedTo = JSON.parse(localStorage.getItem("user"));
  const [buttonWidth, setButtonWidth] = useState(
    window.innerWidth <= 768 ? "100%" : "auto"
  );
  return (
    <PageStructure>
      <h2>Dashboard</h2>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "10px",
          marginBottom: "10px",
        }}
      >
        <AddTicketButton />
        <AddReservationButton />
        <CreateChatroomButton />
      </div>

      <h3>Tickets assigned to me</h3>
      <TicketTable
        mini={true}
        searchUsers={
          assignedTo
            ? [
                {
                  value: {
                    _id: assignedTo._id || assignedTo.userId,
                    username: assignedTo.username,
                    role: assignedTo.role,
                    token: assignedTo.token,
                    name: assignedTo.name,
                  },
                  label: assignedTo.username,
                },
              ]
            : []
        }
        searchStatus={[
          {
            value: {
              color: "#09fbf7",
              title: "In Progress",
              __v: 0,
              _id: "660be69afebd962dd37c663d",
            },
            label: "In Progress",
          },
          {
            value: {
              color: "#11ff00",
              title: "Open",
              __v: 0,
              _id: "65fdd8c6ab895f5bb3aa645b",
            },
            label: "Open",
          },
        ]}
        createdBy={false}
      />
      <button
        className="add-ticket-button__button"
        style={{
          backgroundColor: "var(--primary-color)",
          color: "var(--primary-text-color)",
          fontSize: "15px",
          fontWeight: "600",
          width: buttonWidth,
        }}
        onClick={() => {
          window.location.href = "/tickets/assigned-to-me";
        }}
      >
        View More
      </button>

      <div
        style={{
          display: JSON.parse(localStorage.getItem("user")).permissions
            .canCreateTicket
            ? "flex"
            : "none",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <h3>Tickets Created by Me</h3>
        <TicketTable mini={true} createdBy={true} />
        <button
          className="add-ticket-button__button"
          style={{
            backgroundColor: "var(--primary-color)",
            color: "var(--primary-text-color)",
            fontSize: "15px",
            fontWeight: "600",
            width: buttonWidth,
          }}
          onClick={() => {
            window.location.href = "/tickets/created-by-me";
          }}
        >
          View More
        </button>
      </div>
      <div
        style={{
          display: JSON.parse(localStorage.getItem("user")).permissions
            .canViewCompletedTickets
            ? "flex"
            : "none",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <h3>Completed Tickets</h3>
        <TicketTable
          mini={true}
          createdBy={false}
          searchStatus={[
            {
              value: {
                color: "#1909fb",
                title: "Completed",
                _id: "65fdd8ccab895f5bb3aa645e",
              },
              label: "Completed",
            },
          ]}
        />
        <button
          className="add-ticket-button__button"
          style={{
            backgroundColor: "var(--primary-color)",
            color: "var(--primary-text-color)",
            fontSize: "15px",
            fontWeight: "600",
            width: buttonWidth,
          }}
          onClick={() => {
            window.location.href = "/tickets/completed";
          }}
        >
          View More
        </button>
      </div>

      <div
        style={{
          height: "300px",
        }}
      >
        {/* <Chatrooms /> */}
      </div>
    </PageStructure>
  );
};

export default Dashboard;
