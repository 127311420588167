import React, { useState } from "react";
import { TextField, Alert } from "@mui/material";
import { Delete } from "@mui/icons-material";

import axios from "axios";

const DeleteTicket = ({ ticket }) => {
  const [propertyName, setPropertyName] = useState(ticket.name);
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [buttonWidth, setButtonWidth] = useState(
    window.innerWidth <= 768 ? "100%" : "auto"
  );

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const user = JSON.parse(localStorage.getItem("user"));

  const handleDeleteTicket = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

   

    // Delete your API call here to delete the property
    try {
      const response = await axios.put(
        `https://maliarakis-server.rhodesislandpass.com/ticket/deleteTicket/${ticket._id}`,
        {
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setSuccess("Ticket deleted successfully.");
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setError("Ticket already exists.");
      } else {
        setError("Failed to delete ticket. Please try again.");
      }
    }
  };

  return (
    <>
      <div className="delete-property-option-wrapper">
        {error && <Alert severity="error">{error}</Alert>}
        {success && <Alert severity="success">{success}</Alert>}
        <h4
          style={{
            marginBottom: "0px",

            fontWeight: "600",
          }}
        >
          {ticket.name}
        </h4>

        <h5>Are you sure you want to delete this ticket?</h5>

        <button
          className="add-ticket-button__button"
          sx={{
            backgroundColor: "var(--primary-color)",
            color: "var(--primary-text-color)",
            fontSize: "15px",
            fontWeight: "600",
            width: "auto",
            padding: "10px 20px",
          }}
            onClick={handleDeleteTicket}
        >
          Yes
          <Delete />
        </button>
      </div>
    </>
  );
};

export default DeleteTicket;
