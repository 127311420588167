import React, { useEffect } from "react";
import { useLocation } from "react-router-dom"; // Import useLocation
import {
  AddTicketButton,
  Sidebar,
  TaskManager,
  TicketTable,
} from "../../components";
import { PageStructure } from "..";
import "./Tickets.css";

const Tickets = (props) => {
  // This hook returns the current location object
  const location = useLocation();

  useEffect(() => {
    // This effect will run whenever the location (URL) changes
 

    // Place your logic here to fetch new data based on the new URL
    // For example, parsing query strings from the URL and fetching data accordingly
  }, [location]); // Depend on location to trigger effect on URL change

  return (
    <PageStructure>
      <h1>Tickets</h1>
      <h2>{props.subtitle}</h2>
      <AddTicketButton />
      <TicketTable
        searchUsers={
          props.assignedTo
            ? [
                {
                  value: {
                    _id: props.assignedTo._id || props.assignedTo.userId,
                    username: props.assignedTo.username,
                    role: props.assignedTo.role,
                    token: props.assignedTo.token,
                    name: props.assignedTo.name,
                  },
                  label: props.assignedTo.username,
                },
              ]
            : []
        }
        archived={props.archived}
        searchStatus={
          props.searchStatus
            ? props.searchStatus
            : ""
        }
        createdBy={props.createdBy}
      />
    </PageStructure>
  );
};

export default Tickets;
