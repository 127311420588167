import React, { useState, useEffect, useRef } from "react";
import { useUser } from "../../contexts/UserContext";

import "./Properties.css";
import {
  AddPropertyButton,
  ImportPropertyButton,
  PropertiesTable,
  Loader,
} from "../../components";
import { PageStructure } from "../../pages";
import { Add, Task } from "@mui/icons-material";

const Properties = () => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <PageStructure>
      <h2>Properties</h2>

      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              overflow: "hidden",
              overflowX: "auto",
              gap: "10px",
            }}
          >
            <AddPropertyButton />
            <ImportPropertyButton 
                setIsLoading={setIsLoading}
            />
          </div>
          <PropertiesTable />
        </>
      )}
    </PageStructure>
  );
};

export default Properties;
