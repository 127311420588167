import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import axios from "axios";

import { Delete } from "@mui/icons-material";
import { Tooltip, Alert, Autocomplete, TextField } from "@mui/material";
import { FileUpload } from "@mui/icons-material";

const CustomOption = (props) => {
  return (
    <Tooltip
      title={props.data.tooltip}
      placement={props.data.tooltipPlacement || "bottom"}
      arrow
      PopperProps={{
        style: { zIndex: 10001 },
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -10],
            },
          },
        ],
        sx: {
          "& .MuiTooltip-tooltip": {
            fontSize: "14px",
          },
        },
      }}
    >
      <div>
        <components.Option {...props} />
      </div>
    </Tooltip>
  );
};
function formatDate(date) {
  date = new Date(date);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

function formatDateData(date) {
  date = new Date(date);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
}

function extractFilename(filePath) {
  // Use split to divide the path by '-' and slice to ignore the timestamp part
  const parts = filePath.split("-");
  const fileName = parts.slice(3).join("-"); // This assumes the timestamp is always three parts long

  return fileName;
}

const EditTicketForm = (props) => {
  const ticket = props.ticket;
  console.log(props);
  const [ticketData, setTicketData] = useState({
    title: ticket.title,
    description: ticket.description,
    type:
      props.categories &&
      props.categories
        .filter((category) => category._id === ticket.type)
        .map((category) => ({
          value: category,
          label: category.title,
        })), // Add this line

    priority:
      props.priorities &&
      props.priorities
        .filter((priority) => priority._id === ticket.priority)
        .map((priority) => ({
          value: priority,
          label: priority.title,
        })), // Add this line
    status: props.statuses
      ? props.statuses
          .filter((status) => status._id === ticket.status)
          .map((status) => ({
            value: status,
            label: status.title,
          }))
      : "", // Add this line
    group: ticket.groupAssignedTo
      .filter((group) => group !== null)
      .map((group) => ({
        value: props.groups.find((g) => g._id === group),
        label: props.groups.find((g) => g._id === group)?.name,
      })), // Add this line
    assignedTo: ticket.assignedTo.map((user) => ({
      value: props.users?.find((u) => u._id === user),
      label: props.users?.find((u) => u._id === user)?.username,
      tooltip: props.users?.find((u) => u._id === user)?.name,
    })),

    property: props.properties
      .filter((property) => property._id === ticket.property)
      .map((property) => ({
        value: property,
        label: property.name,
      })), // Add this line
    reservationId: "",
    customerFirstName: ticket.customerFirstName,
    customerLastName: ticket.customerLastName,
    dueDate: ticket.dueDate ? formatDateData(ticket.dueDate) : "",
    gonnaHandleAt: ticket.gonnaHandleAt
      ? formatDateData(ticket.gonnaHandleAt)
      : "",
    checkInDate: "",
    checkOutDate: "",
    previousAttachments: ticket.attachments,
    attachments: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [users, setUsers] = useState(props.users);
  const [usersOptions, setUsersOptions] = useState(
    props.users.map((user) => ({
      value: user,
      label: user.username,
      tooltip: user.name,
    }))
  ); // Add this line
  const [reservationIds, setReservationIds] = useState([]);
  const [groups, setGroups] = useState(props.groups); // Add this line
  const [statusOptions, setStatusOptions] = useState(props.statuses);
  const [priorityOptions, setPriorityOptions] = useState(props.priorities);
  const [categoryOptions, setCategoryOptions] = useState(props.categories);
  const [properties, setProperties] = useState(props.properties);
  const [selectedFileNames, setSelectedFileNames] = useState([]);
  const closeWindow = () => {
    props.setShowAddTicketForm(false);
  };
  const user = JSON.parse(localStorage.getItem("user"));

  // Fetching

  const fetchReservationIds = async () => {
    try {
      const response = await axios.get(
        "https://maliarakis-server.rhodesislandpass.com/reservation/getReservationsIds",
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setReservationIds(response.data.reservations);

      console.log("Reservation IDs",
        response.data.reservations
          .filter((reservation) => reservation._id === ticket.reservationId)
          .map((reservation) => ({
            value: reservation,
            label: reservation.reservationId,
            tooltip: `${formatDate(reservation.checkIn)} - ${formatDate(
              reservation.checkOut
            )} | ${reservation.property} | ${reservation.firstName} ${
              reservation.lastName
            }`,
            tooltipPlacement: "right",
          }))[0]
      )
      setTicketData({
        ...ticketData,
        reservationId: response.data.reservations
          .filter((reservation) => reservation._id === ticket.reservationId)
          .map((reservation) => ({
            value: reservation,
            label: reservation.reservationId,
            tooltip: `${formatDate(reservation.checkIn)} - ${formatDate(
              reservation.checkOut
            )} | ${reservation.property} | ${reservation.firstName} ${
              reservation.lastName
            }`,
            tooltipPlacement: "right",
          }))[0],
        customerFirstName: response.data.reservations
          .filter((reservation) => reservation._id === ticket.reservationId)
          .map((reservation) => reservation.firstName)[0],
        customerLastName: response.data.reservations
          .filter((reservation) => reservation._id === ticket.reservationId)
          .map((reservation) => reservation.lastName)[0],
        checkInDate: response.data.reservations
          .filter((reservation) => reservation._id === ticket.reservationId)
          .map((reservation) => formatDateData(reservation.checkIn))[0],
        checkOutDate: response.data.reservations
          .filter((reservation) => reservation._id === ticket.reservationId)
          .map((reservation) => formatDateData(reservation.checkOut))[0],
      });
    } catch (error) {
      console.error("Failed to fetch reservation IDs: ", error);
    }
  };

  const handleGroupChange = (selectedGroups) => {
    const newUsers = selectedGroups.flatMap((group) =>
      group.value.users.map((user) => ({
        value: user,
        label: user.username,
        tooltip: user.name,
      }))
    );

    setTicketData({
      ...ticketData,
      group: selectedGroups,
      assignedTo: [...ticketData.assignedTo, ...newUsers],
    });

    setUsersOptions(
      usersOptions.filter(
        (user) =>
          !selectedGroups
            .flatMap((group) => group.value.users.map((user) => user._id))
            .includes(user.value._id)
      )
    );
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setTicketData({ ...ticketData, [name]: value });
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    const fileNames = Array.from(files).map((file) => file.name);
    setTicketData({ ...ticketData, attachments: [...files] });
    setSelectedFileNames(fileNames); // Update state with file names
  };

  const removeFile = (fileNameToRemove) => {
    // Filter out the file to remove from the attachments array
    const updatedFiles = ticketData.attachments.filter(
      (file) => file.name !== fileNameToRemove
    );

    // Update the attachments in ticketData state
    setTicketData({ ...ticketData, attachments: updatedFiles });

    // Update the selected file names to reflect this removal
    const updatedFileNames = selectedFileNames.filter(
      (fileName) => fileName !== fileNameToRemove
    );
    setSelectedFileNames(updatedFileNames);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setErrorMessage("");
    setSuccessMessage("");

    if (ticketData.title === "") {
      setIsLoading(false);
      setErrorMessage("Title is required.");
      return;
    }

    if (ticketData.description === "") {
      setIsLoading(false);
      setErrorMessage("Description is required.");
      return;
    }

    if (ticketData.assignedTo.length === 0) {
      setIsLoading(false);
      setErrorMessage("Assign To is required.");
      return;
    }

    const dataToSend = {
      title: ticketData.title,
      description: ticketData.description,
      type:
        ticketData.type?.value?._id ||
        categoryOptions.find((category) => category.title === "Other")?._id ||
        null,
      priority:
        ticketData.priority?.value?._id ||
        priorityOptions.find((priority) => priority.title === "Low")?._id ||
        null,
      status:
        ticketData.status?.value?._id ||
        statusOptions.find((status) => status.title === "Open")?._id ||
        null,
      assignedTo: ticketData.assignedTo?.map((user) => user.value._id) || [],
      group: ticketData.group?.map((group) => group.value) || [],
      gonnaHandleAt: ticketData.gonnaHandleAt || "",
      property: ticketData.property?.value?._id || null,
      reservationId: ticketData.reservationId?.value?._id || null,
      customerFirstName: ticketData.customerFirstName || null,
      customerLastName: ticketData.customerLastName || null,
      checkInDate: ticketData.checkInDate || "",
      checkOutDate: ticketData.checkOutDate || "",
      dueDate: ticketData.dueDate || "",
      attachmentsToDelete: props.ticket.attachments.filter(
        (attachment) => !ticketData.previousAttachments.includes(attachment)
      ),
      attachments: ticketData.attachments || [],
      userId: user.userId,
    };

    try {
      const formData = new FormData();
      formData.append("title", dataToSend.title);
      formData.append("description", dataToSend.description);
      formData.append("type", dataToSend.type);
      formData.append("priority", dataToSend.priority);
      formData.append("status", dataToSend.status);
      formData.append("groupAssignedTo", JSON.stringify(dataToSend.group));
      formData.append("assignedTo", JSON.stringify(dataToSend.assignedTo));
      formData.append("property", dataToSend.property);
      formData.append("reservationId", dataToSend.reservationId);
      formData.append("customerFirstName", dataToSend.customerFirstName);
      formData.append("customerLastName", dataToSend.customerLastName);
      formData.append("checkInDate", dataToSend.checkInDate);
      formData.append("checkOutDate", dataToSend.checkOutDate);
      formData.append("dueDate", dataToSend.dueDate);
      formData.append("gonnaHandleAt", dataToSend.gonnaHandleAt);
      formData.append("userId", dataToSend.userId);
      formData.append(
        "attachmentsToDelete",
        JSON.stringify(dataToSend.attachmentsToDelete)
      );
      for (let i = 0; i < dataToSend.attachments.length; i++) {
        formData.append("attachments", dataToSend.attachments[i]);
      }

      const response = await axios.put(
        `https://maliarakis-server.rhodesislandpass.com/ticket/updateTicket/${ticket._id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setIsLoading(false);
      setSuccessMessage("Ticket updated successfully!");
      setTimeout(() => {}, 1500);
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(
        error.response?.data?.message || "Failed to create ticket."
      );
    }
  };

  useEffect(() => {
    fetchReservationIds();
  }, []);

  return (
    <div className="ticket-form-wrapper">
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <form className="ticket-form" onSubmit={handleSubmit}>
          {/* Title */}
          <div className="form-group">
            <div className="form-field">
              <label className="form-field-label" htmlFor="title">
                Title:
              </label>

              <input
                className="form-field-input"
                type="text"
                id="title"
                name="title"
                value={ticketData.title}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>

          {/* Description */}
          <div className="form-group">
            <div className="form-field">
              <label htmlFor="description" className="form-field-label">
                Description:
              </label>

              <textarea
                id="description"
                name="description"
                className="form-field-input"
                value={ticketData.description}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>

          {/* ReservationID || Type */}
          <div className="form-group">
            <div className="form-field">
              <label htmlFor="reservationId" className="form-field-label">
                Reservation ID:
              </label>
              {/* <Select
                id="reservationId"
                name="reservationId"
                isClearable
                // isDisabled={true}
                value={ticketData.reservationId}
                onChange={(selectedOption) => {
                  console.log(ticketData);
                  setTicketData({
                    ...ticketData,
                    reservationId: selectedOption, // Make sure you're using selectedOption.value
                    customerFirstName: selectedOption.value.firstName, // This should come from the selected option's additional data
                    customerLastName: selectedOption.value.lastName, // Same here
                    checkInDate: formatDateData(selectedOption.value.checkIn), // Same here
                    checkOutDate: formatDateData(selectedOption.value.checkOut), // Same here
                    property: {
                      value: properties.find(
                        (property) =>
                          property.name === selectedOption.value.property
                      ),
                      label: selectedOption.value.property,
                    }, // Same here
                  });
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "var(--secondary-color)",
                    primary25: "var(--tertiary-color)",
                  },
                })}
                options={
                  reservationIds &&
                  reservationIds.map((reservation) => ({
                    value: reservation,
                    label: reservation.reservationId,
                    tooltip: `${formatDate(reservation.checkIn)} - ${formatDate(
                      reservation.checkOut
                    )} | ${reservation.property} | ${reservation.firstName} ${
                      reservation.lastName
                    }`,
                    tooltipPlacement: "right",
                  }))
                } // Add more options as needed
                components={{ Option: CustomOption }}
              /> */}

              <Autocomplete
                disablePortal
                id="reservationId"
                name="reservationId"
                value={
                 ticketData.reservationId
                  
                }
                options={
                  reservationIds &&
                  reservationIds.map((reservation) => ({
                    value: reservation,
                    label: reservation.reservationId,
                    tooltip: `${formatDate(reservation.checkIn)} - ${formatDate(
                      reservation.checkOut
                    )} | ${reservation.property} | ${reservation.firstName} ${
                      reservation.lastName
                    }`,
                    tooltipPlacement: "right",
                  }))
                }
                // renderOption={
                //   (props, option) => (
                //     <Tooltip
                //     title={option.tooltip}
                //     placement={option.tooltipPlacement || "bottom"}
                //     arrow
                //     PopperProps={{
                //       style: { zIndex: 10001 },
                //       modifiers: [
                //         {
                //           name: "offset",
                //           options: {
                //             offset: [0, -10],
                //           },
                //         },
                //       ],
                //       sx: {
                //         "& .MuiTooltip-tooltip": {
                //           fontSize: "14px",
                //         },
                //       },
                //     }}
                //     >
                //       <li {...props}>{option.label}</li>
                //     </Tooltip>
                //   )
                // }
                onChange={(event, newValue) => {
                  if (!newValue) {
                    setTicketData({
                      ...ticketData,
                      reservationId: "",
                      customerFirstName: "",
                      customerLastName: "",
                      checkInDate: "",
                      checkOutDate: "",
                      property: "",
                    });
                    return;
                  }
                  setTicketData({
                    ...ticketData,
                    reservationId: 
                    {
                      value: newValue.value,
                      label: newValue.label,
                    
                    },
                    customerFirstName: newValue.value.firstName,
                    customerLastName: newValue.value.lastName,
                    checkInDate: formatDateData(newValue.value.checkIn),
                    checkOutDate: formatDateData(newValue.value.checkOut),
                    property: {
                      value: properties.find(
                        (property) => property.name === newValue.value.property
                      ),
                      label: newValue.value.property,
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Reservation ID" />
                )}
              />
            </div>
            <div className="form-field">
              <label htmlFor="type" className="form-field-label">
                Type:
              </label>

              <Select
                id="type"
                name="type"
                value={ticketData.type}
                onChange={(selectedOption) =>
                  setTicketData({ ...ticketData, type: selectedOption })
                }
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "var(--secondary-color)",
                    primary25: "var(--tertiary-color)",
                  },
                })}
                options={
                  categoryOptions &&
                  categoryOptions.map((category) => ({
                    value: category,
                    label: category.title,
                  }))
                } // Add more options as needed
                isClearable
              />
            </div>
          </div>

          {/* Ticket Due Date || Gonna Handle At */}
          <div className="form-group">
            <div className="form-field">
              <label htmlFor="dueDate" className="form-field-label">
                Ticket Due Date:
              </label>
              <input
                id="dueDate"
                name="dueDate"
                type="date"
                // min={formatDateData(new Date())}
                className="form-field-input"
                value={ticketData.dueDate}
                onChange={handleInputChange}
              />
            </div>

            <div className="form-field">
              <label htmlFor="gonnaHandleAt" className="form-field-label">
                Gonna Handle At:
              </label>
              <input
                id="gonnaHandleAt"
                name="gonnaHandleAt"
                type="date"
                // min={formatDateData(new Date())}
                className="form-field-input"
                value={ticketData.gonnaHandleAt}
                onChange={handleInputChange}
              />
            </div>
          </div>

          {/* Group || Assign To */}
          <div className="form-group">
            <div className="form-field">
              <label htmlFor="group" className="form-field-label">
                Group:
              </label>

              <Select
                id="group"
                name="group"
                value={ticketData.group}
                onChange={handleGroupChange}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "var(--secondary-color)",
                    primary25: "var(--tertiary-color)",
                  },
                })}
                options={
                  groups &&
                  groups.map((group) => ({
                    value: group,
                    label: group.name,
                  }))
                } // Add more options as needed
                isMulti
                isClearable
              />
            </div>
            <div className="form-field">
              <label htmlFor="assignTo" className="form-field-label">
                Assign To:
              </label>

              <Select
                required
                id="assignTo"
                name="assignTo"
                value={ticketData.assignedTo}
                onChange={(selectedOption) => {
                  setUsersOptions(
                    users
                      .filter(
                        (user) =>
                          !selectedOption
                            .map((option) => option.value._id)
                            .includes(user._id)
                      )
                      .map((user) => ({
                        value: user,
                        label: user.username,
                        tooltip: user.name,
                      }))
                  );
                  setTicketData({
                    ...ticketData,
                    assignedTo: selectedOption,
                  });
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "var(--secondary-color)",
                    primary25: "var(--tertiary-color)",
                  },
                })}
                options={usersOptions} // Add more options as needed
                isMulti
                isClearable
                components={{ Option: CustomOption }}
              />
            </div>
          </div>

          {/* Importance || Status*/}
          <div className="form-group">
            <div className="form-field">
              <label htmlFor="priority" className="form-field-label">
                Importance:
              </label>

              <Select
                id="priority"
                name="priority"
                value={ticketData.priority}
                onChange={(selectedOption) =>
                  setTicketData({ ...ticketData, priority: selectedOption })
                }
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "var(--secondary-color)",
                    primary25: "var(--tertiary-color)",
                  },
                })}
                options={
                  priorityOptions &&
                  priorityOptions.map((priority) => ({
                    value: priority,
                    label: priority.title,
                  }))
                } // Add more options as needed
                isClearable
              />
            </div>

            <div className="form-field">
              <label htmlFor="status" className="form-field-label">
                Status:
              </label>

              <Select
                id="status"
                name="status"
                value={ticketData.status}
                onChange={(selectedOption) =>
                  setTicketData({ ...ticketData, status: selectedOption })
                }
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "var(--secondary-color)",
                    primary25: "var(--tertiary-color)",
                  },
                })}
                options={
                  statusOptions &&
                  statusOptions.map((status) => ({
                    value: status,
                    label: status.title,
                  }))
                } // Add more options as needed
                isClearable
              />
            </div>
          </div>

          {/* Property || Customer First Name || Customer Last Name */}
          <div className="form-group">
            <div className="form-field">
              <label htmlFor="property" className="form-field-label">
                Property:
              </label>
              <Select
                id="property"
                name="property"
                value={ticketData.property}
                onChange={(selectedOption) => {
                  console.log(ticketData);
                  setTicketData({ ...ticketData, property: selectedOption });
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "var(--secondary-color)",
                    primary25: "var(--tertiary-color)",
                  },
                })}
                options={
                  properties &&
                  properties.map((property) => ({
                    value: property,
                    label: property.name,
                  }))
                } // Add more options as needed
              />
            </div>

            <div className="form-field">
              <label htmlFor="customerFirstName" className="form-field-label">
                Customer First Name:
              </label>
              <input
                id="customerFirstName"
                name="customerFirstName"
                type="text"
                className="form-field-input"
                value={ticketData.customerFirstName}
                onChange={handleInputChange}
              />
            </div>

            <div className="form-field">
              <label htmlFor="customerLastName" className="form-field-label">
                Customer Last Name:
              </label>
              <input
                id="customerLastName"
                name="customerLastName"
                type="text"
                className="form-field-input"
                value={ticketData.customerLastName}
                onChange={handleInputChange}
              />
            </div>
          </div>

          {/* Check In Date || Check Out Date */}
          <div className="form-group">
            <div className="form-field">
              <label htmlFor="checkInDate" className="form-field-label">
                Check In Date:
              </label>
              <input
                id="checkInDate"
                name="checkInDate"
                type="date"
                className="form-field-input"
                value={ticketData.checkInDate}
                onChange={handleInputChange}
              />
            </div>

            <div className="form-field">
              <label htmlFor="checkOutDate" className="form-field-label">
                Check Out Date:
              </label>
              <input
                id="checkOutDate"
                name="checkOutDate"
                type="date"
                className="form-field-input"
                value={ticketData.checkOutDate}
                onChange={handleInputChange}
              />
            </div>
          </div>

          {/* Attachments  */}
          <div
            className="form-group"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "start",
            }}
          >
            {ticketData.previousAttachments.length > 0 && (
              <div className="file-list">
                <p>Previous Attachments:</p>
                <ul>
                  {ticketData.previousAttachments.map((attachment, index) => (
                    <li
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      {extractFilename(attachment)}
                      <Tooltip
                        title="Remove File"
                        PopperProps={{
                          sx: {
                            "& .MuiTooltip-tooltip": {
                              fontSize: "15px",
                            },
                          },
                          style: { zIndex: 10001, fontSize: "16px" },
                          modifiers: [
                            {
                              name: "offset",
                              options: {
                                offset: [0, -10],
                              },
                            },
                          ],
                        }}
                        arrow
                        placement="right"
                      >
                        <Delete
                          sx={{
                            color: "var(--primary-color)",
                            fontSize: "20px",
                            cursor: "pointer",
                            "&:hover": {
                              color: "var(--secondary-color)",
                            },
                            transition: "color 0.3s",
                            alignSelf: "center",
                            textAlign: "center",

                            padding: "10px",
                          }}
                          onClick={() =>
                            setTicketData({
                              ...ticketData,
                              previousAttachments:
                                ticketData.previousAttachments.filter(
                                  (file) => file !== attachment
                                ),
                            })
                          }
                        />
                      </Tooltip>
                    </li>
                  ))}
                </ul>
                <p>Total Files: {ticketData.previousAttachments.length}</p>
              </div>
            )}

            <Tooltip
              title="Upload Files"
              PopperProps={{
                sx: {
                  "& .MuiTooltip-tooltip": {
                    fontSize: "15px",
                  },
                },
                style: { zIndex: 10001, fontSize: "16px" },
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -10],
                    },
                  },
                ],
              }}
              arrow
              placement="bottom"
            >
              <div className="form-field">
                <label
                  htmlFor="attachments"
                  className="form-field-label custom-file-upload"
                >
                  Attachments:
                  <input
                    type="file"
                    className="form-field-input"
                    id="attachments"
                    name="attachments"
                    multiple
                    onChange={handleFileChange}
                  />
                  <FileUpload
                    sx={{
                      color: "var(--primary-color)",
                      fontSize: "40px",
                      cursor: "pointer",
                      alignSelf: "center",
                      pt: "10px",
                      pb: "10px",
                      "&:hover": {
                        color: "var(--secondary-color)",
                      },
                      transition: "color 0.3s",
                    }}
                  />
                </label>
              </div>
            </Tooltip>
            {selectedFileNames.length > 0 && (
              <div className="file-list">
                <p>Selected Files:</p>
                <ul>
                  {selectedFileNames.map((fileName, index) => (
                    <li
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      {fileName}
                      <Tooltip
                        title="Remove File"
                        PopperProps={{
                          sx: {
                            "& .MuiTooltip-tooltip": {
                              fontSize: "15px",
                            },
                          },
                          style: { zIndex: 10001, fontSize: "16px" },
                          modifiers: [
                            {
                              name: "offset",
                              options: {
                                offset: [0, -10],
                              },
                            },
                          ],
                        }}
                        arrow
                        placement="right"
                      >
                        <Delete
                          sx={{
                            color: "var(--primary-color)",
                            fontSize: "20px",
                            cursor: "pointer",
                            "&:hover": {
                              color: "var(--secondary-color)",
                            },
                            transition: "color 0.3s",
                            alignSelf: "center",
                            textAlign: "center",

                            padding: "10px",
                          }}
                          onClick={() => removeFile(fileName)}
                        />
                      </Tooltip>
                    </li>
                  ))}
                </ul>
                <p>Total Files: {selectedFileNames.length}</p>
              </div>
            )}
          </div>

          {errorMessage && (
            <Alert severity="error" sx={{ marginBottom: "10px" }}>
              {errorMessage}
            </Alert>
          )}

          {successMessage && (
            <Alert severity="success" sx={{ marginBottom: "10px" }}>
              {successMessage}
            </Alert>
          )}

          {/* Submit Button */}

          <div className="form-group">
            <div className="form-field">
              <button type="submit" className="submit-button">
                Edit Ticket
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default EditTicketForm;
