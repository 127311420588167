import React from "react";
import "./popUpWindow.css";

function PopUpWindow({
  children,
  title,
  onClose,
  color,
  width,
  unfocusClose = true,
}) {
  const handleCloseClick = () => {
    onClose();
  };

  const handleWrapperClick = (e) => {
    if (e.target.classList.contains("pop-up-window-wrapper")) {
      onClose();
    }
  };

  const findContrastColor = (hexColor) => {
    const r = parseInt(hexColor.substr(1, 2), 16);
    const g = parseInt(hexColor.substr(3, 2), 16);
    const b = parseInt(hexColor.substr(5, 2), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;

    return yiq >= 128 ? "#6a6a6a" : "#B5C2CF";
  };

  return (
    <div
      className="pop-up-window-wrapper"
      onClick={unfocusClose ? handleWrapperClick : null}
    >
      <div
        className="pop-up-window"
        style={{
          backgroundColor: color || "white",
          width: window.innerWidth <= 768 ? "100%" : width || "80%",
        }}
      >
        <div className="pop-up-window-header">
          <div
            className="pop-up-window-title"
            style={{
              color: findContrastColor(color || "#ffffff"),
            }}
          >
            {title}
          </div>
          <div
            className="pop-up-window-close"
            onClick={handleCloseClick}
            style={{
              color: findContrastColor(color || "#ffffff"),
            }}
          >
            X
          </div>
        </div>
        <div className="pop-up-window-body">{children}</div>
      </div>
    </div>
  );
}

export default PopUpWindow;
