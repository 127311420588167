import React, { useState, useEffect } from "react";
import axios from "axios";
import Select, { components } from "react-select";
import { Checkbox, Tooltip, Alert } from "@mui/material";
import "./usersPermissions.css";
import Loader from "../loader/Loader";

const CustomOption = (props) => {
  return (
    <Tooltip
      title={props.data.tooltip}
      placement={props.data.tooltipPlacement || "bottom"}
      arrow
      PopperProps={{
        style: { zIndex: 10001 },
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -10],
            },
          },
        ],
        sx: {
          "& .MuiTooltip-tooltip": {
            fontSize: "14px",
          },
        },
      }}
    >
      <div>
        <components.Option {...props} />
      </div>
    </Tooltip>
  );
};
const UsersPermissions = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  const [permissions, setPermissions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("https://maliarakis-server.rhodesislandpass.com/auth/getUsers", {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("user")).token
          }`,
        },
      });
      console.log(response.data.users);
      setUsers(response.data.users);
    } catch (error) {
      console.error("Failed to fetch users: ", error);
      setErrorMessage("Failed to fetch users.");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleUserChange = (selectedOption) => {
    setSelectedUser(selectedOption);
  };

  const handlePermissionChange = async (permission) => {
    setErrorMessage("");
    setSuccessMessage("");
    setIsLoading(true);

    try {
      const response = await axios.put(
        `https://maliarakis-server.rhodesislandpass.com/auth/changePermissions/${selectedUser.value._id}`,
        {
          userId: selectedUser.value,
          permission: permission,
        },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("user")).token
            }`,
          },
        }
      );
      setSuccessMessage("Permissions updated successfully.");
      setUsers(
        users.map((user) => {
          if (user._id === selectedUser.value._id) {
            return {
              ...user,
              permissions: response.data.permissions,
            };
          }
          return user;
        })
      );
      setSelectedUser({
        ...selectedUser,
        value: {
          ...selectedUser.value,
          permissions: response.data.permissions,
        },
      });
    } catch (error) {
      console.error("Failed to update permissions: ", error);
      setErrorMessage("Failed to update permissions.");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!isLoading && selectedUser) {
      setPermissions(Object.keys(selectedUser.value.permissions));
    }
  }, [selectedUser]);

  return (
    <div className="users-permissions">
      <Select
        className="users-permissions__select"
        options={users.map((user) => ({
          value: user,
          label: user.username,
          tooltip: user.name,
        }))}
        onChange={handleUserChange}
        placeholder="Select a user..."
        components={{ Option: CustomOption }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: "var(--secondary-color)",
            primary25: "var(--tertiary-color)",
          },
        })}
        isClearable
      />
      {errorMessage && (
        <Alert severity="error" className="users-permissions__error">
          {errorMessage}
        </Alert>
      )}
      {successMessage && (
        <Alert severity="success" className="users-permissions__success">
          {successMessage}
        </Alert>
      )}
      {!isLoading && selectedUser && (
        <div className="users-permissions__permissions">
          <h2>Permissions</h2>
          {permissions.map((permission) => (
            <div key={permission} className="users-permissions__permission">
              <Checkbox
                checked={selectedUser.value.permissions[permission]}
                onChange={() => handlePermissionChange(permission)}
              />
              <span>{permission}</span>
            </div>
          ))}
        </div>
      )}
      {isLoading && <Loader />}
    </div>
  );
};

export default UsersPermissions;
