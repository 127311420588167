import React from "react";
import { useUser } from "../../contexts/UserContext";

import "./TicketSettings.css";
import {
  AddTicketButton,
  Sidebar,
  TaskManager,
  TicketTable,
  AddCategoryOption,
  AddPriorityOption,
  AddStatusOption,
  UpdateCategoryOption,
  UpdatePriorityOption,
  UpdateStatusOption,
} from "../../components";
import { PageStructure } from "../../pages";


const TicketSettings = () => {
  return (
    <PageStructure>
      <h1>Ticket Settings</h1>

      <hr />
      <h3 style={
        {textAlign: "center"}
      } >Category Settings</h3>
      <AddCategoryOption />
      <UpdateCategoryOption />
      <hr />
        <h3 style={
            {textAlign: "center"}
        } >Priority Settings</h3>
        <AddPriorityOption />
        <UpdatePriorityOption />
        <hr />
        <h3 style={
            {textAlign: "center"}
        } >Status Settings</h3>
        <AddStatusOption />
        <UpdateStatusOption 
         
        />
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                height:"120px"
            }}
        />

    </PageStructure>
  );
};

export default TicketSettings;
