import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Tooltip,
  IconButton,
  TextField,
  TableSortLabel,
  Button,
  Pagination,
  Typography,
  InputAdornment,
  Avatar,
} from "@mui/material";
import { Edit, Delete, Search, Clear } from "@mui/icons-material";
import { EditGroupForm, PopUpWindow, DeleteGroup } from "../";

const formatDateWithTime = (date) => {
  date = new Date(date);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${day}/${month}/${year} ${hours}:${minutes}`;
};

const getColor = (name) => {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = `hsl(${hash % 360}, 100%, 30%)`;
  return color;
};

const AssignedToCell = ({ userName }) => {
  return (
    <Box display="flex" gap={1} justifyContent={"center"}>
      <Tooltip key={userName} title={userName}>
        <Avatar style={{ backgroundColor: getColor(userName) }}>
          {userName.charAt(0)}
        </Avatar>
      </Tooltip>
    </Box>
  );
};
const GroupsTable = () => {
  const [groups, setGroups] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalGroups, setTotalGroups] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [sortField, setSortField] = useState("updatedAt");
  const [sortOrder, setSortOrder] = useState(-1);
  const user = JSON.parse(localStorage.getItem("user"));
  const [editPopUpOpen, setEditPopUpOpen] = useState(false);
  const [editGroup, setEditGroup] = useState(null);
  const [deletePopUpOpen, setDeletePopUpOpen] = useState(false);
  const [deleteGroup, setDeleteGroup] = useState(null);

  useEffect(() => {
    fetchGroups(search, currentPage, sortField, sortOrder);
  }, [currentPage, sortField, sortOrder]);

  const fetchGroups = async (search, page, sortField, sortOrder) => {
    try {
      const response = await axios.get(
        `https://maliarakis-server.rhodesislandpass.com/group/getGroupsWithPagination?search=${search}&page=${page}&sortField=${sortField}&sortOrder=${
          sortOrder === 1 ? "asc" : "desc"
        }`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setGroups(response.data.groups);
      setTotalGroups(response.data.totalItems);
      setPageCount(Math.ceil(response.data.totalItems / itemsPerPage));
    } catch (error) {
      console.error("Failed to fetch groups: ", error);
    }
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    setCurrentPage(1); // Reset to first page when search is submitted
    fetchGroups(search, 1, sortField, sortOrder);
  };

  const handleClearSearch = () => {
    setSearch("");
    setCurrentPage(1); // Reset to first page when search is cleared
    fetchGroups("", 1, sortField, sortOrder);
  };

  const handleSort = (field) => {
    if (sortField === field) {
      setSortOrder(sortOrder * -1);
    } else {
      setSortField(field);
      setSortOrder(-1);
    }
  };

  return (
    <>
      {editPopUpOpen && (
        <PopUpWindow
          onClose={() => {
            setEditPopUpOpen(false);
            setEditGroup(null);
          }}
        >
          <EditGroupForm property={editGroup} />
        </PopUpWindow>
      )}

      {deletePopUpOpen && (
        <PopUpWindow
          onClose={() => {
            setDeletePopUpOpen(false);
            setDeleteGroup(null);
          }}
        >
          <DeleteGroup property={deleteGroup} />
        </PopUpWindow>
      )}

      <Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <form
            onSubmit={handleSearchSubmit}
            style={{
              display: "flex",
              flexDirection: "row",
              width: "95%",
              padding: "10px",
            }}
          >
            <TextField
              variant="outlined"
              placeholder="Search"
              value={search}
              onChange={handleSearchChange}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                endAdornment: search && (
                  <InputAdornment position="end">
                    <Tooltip title="Clear search">
                      <IconButton
                        onClick={handleClearSearch}
                        aria-label="clear search"
                      >
                        <Clear />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
              style={{
                display: "flex",
                flexDirection: "row",
                width: "95%",
                padding: "10px",
              }}
            />
            <Button
              type="submit"
              variant="contained"
              style={{
                marginLeft: "10px",
                padding: "10px",
                width: "20%",
                backgroundColor: "var(--primary-color)",
                color: "var(--primary-text-color)",
                fontSize: "15px",
                fontWeight: "500",
              }}
            >
              Search
            </Button>
          </form>
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={sortField === "name"}
                    direction={sortOrder === 1 ? "asc" : "desc"}
                    onClick={() => handleSort("name")}
                  >
                    Name
                  </TableSortLabel>
                </TableCell>

                <TableCell>
                  <TableSortLabel
                    active={sortField === "users"}
                    direction={sortOrder === 1 ? "asc" : "desc"}
                    onClick={() => handleSort("users")}
                  >
                    Users
                  </TableSortLabel>
                </TableCell>

                <TableCell>
                  <TableSortLabel
                    active={sortField === "updatedAt"}
                    direction={sortOrder === 1 ? "asc" : "desc"}
                    onClick={() => handleSort("updatedAt")}
                  >
                    Updated At
                  </TableSortLabel>
                </TableCell>
                {(user.permissions.canEditGroup ||
                  user.permissions.canDeleteGroup) && (
                  <TableCell>Actions</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {groups.map((property) => (
                <TableRow key={property.id}>
                  <TableCell>{property.name}</TableCell>
                  <TableCell>
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {property.users.map((user) => (
                        <AssignedToCell key={user._id} userName={user.name} />
                      ))}
                    </div>
                  </TableCell>

                  <TableCell>
                    {formatDateWithTime(property.updatedAt)}
                  </TableCell>
                  {(user.permissions.canEditGroup ||
                    user.permissions.canDeleteGroup) && (
                    <TableCell>
                      {user.permissions.canEditGroup && (
                        <Tooltip title="Edit" color="secondary">
                          <IconButton
                            onClick={() => {
                              setEditGroup(property);
                              setEditPopUpOpen(true);
                            }}
                          >
                            <Edit sx={{ color: "var(--primary-color)" }} />
                          </IconButton>
                        </Tooltip>
                      )}
                      {user.permissions.canDeleteGroup && (
                        <Tooltip title="Delete" color="primary">
                          <IconButton
                            onClick={() => {
                              setDeleteGroup(property);
                              setDeletePopUpOpen(true);
                            }}
                          >
                            <Delete sx={{ color: "var(--primary-color)" }} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          m={2}
          sx={{ width: "95%" }}
        >
          <Typography>
            Showing {(currentPage - 1) * itemsPerPage}-
            {Math.min(currentPage * itemsPerPage, totalGroups)} of {totalGroups}{" "}
            items
          </Typography>
          <Pagination
            count={pageCount}
            page={currentPage}
            onChange={(event, page) => {
              setCurrentPage(page);
            }}
            color="primary"
          />
        </Box>
      </Box>
    </>
  );
};

export default GroupsTable;
