import React, { useState } from "react";
import { Button, TextField, Grid, Container, Typography, IconButton, InputAdornment } from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material"
import Loader from "../loader/Loader";

import { useUser } from "../../contexts/UserContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function LoginForm({ setIsLogin }) {

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false); 
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({ username: "", password: "" });
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const setUser = useUser().setUser;

  const login = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post("https://maliarakis-server.rhodesislandpass.com/auth/login", {
        email: username,
        password: password,
      });
      console.log(response.data.user);
      // Set the user in the context
      setUser(response.data.user);
      localStorage.setItem("user", JSON.stringify(response.data.user));
      localStorage.setItem("logout", new Date().getTime() + 1000 * 60 * 60 * 24);
      setIsLoading(false);

      navigate("/dashboard");
    }
    catch (error) {
      console.error("Error logging in: ", error);
      setErrorMessage("Invalid username or password.");
      setIsLoading(false);
    }
  };


  const validate = () => {
    let isValid = true;
    const newErrors = { username: "", password: "" };

    if (!username) {
      newErrors.username = "Username cannot be empty.";
      isValid = false;
    }
    if (!password) {
      newErrors.password = "Password cannot be empty.";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = (event) => {
    setErrorMessage("");
    setErrors({ username: "", password: "" });
    event.preventDefault();
    if (!validate()) {
      console.log("Validation failed.");
      return;
    }

    login();
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container maxWidth="sm">

      {isLoading && <Loader message={
        "Loading..."
      }/>}



    { !isLoading &&(
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            Login
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Username / Email"
            variant="outlined"
            fullWidth
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
              setErrors({ ...errors, username: "" });
            }}
            error={!!errors.username}
            helperText={errors.username}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Password"
            variant="outlined"
            fullWidth
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              setErrors({ ...errors, password: "" });
            }}
            error={!!errors.password}
            helperText={errors.password}
            InputProps={{ // Add an icon button inside the text field
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        {errorMessage !== "" && (
            <Grid item xs={12}>
              <p
                style={{
                  color: "red",
                  padding: "10px",
                  backgroundColor: "#FFC1C1E0",
                }}
              >
                {errorMessage}
              </p>
            </Grid>
          )}
        <Grid item xs={4}>
          <Button variant="contained" type="submit" onClick={handleSubmit}>
            Login
          </Button>
        </Grid>
        <Grid item xs={8}>
          <p onClick={() => setIsLogin(false)} style={{ cursor: "pointer", color: "var(--primary-color)" }}>
            Create an account
          </p>
        </Grid>
      </Grid>)}
    </Container>
  );
}

export default LoginForm;
