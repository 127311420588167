import React, { useState } from "react";
import { TextField, Alert } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import axios from "axios";

const AddCategoryOption = () => {
  const [categoryName, setCategoryName] = useState("");
  const [categoryColor, setCategoryColor] = useState("#FA0808");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const user = JSON.parse(localStorage.getItem("user"));

  const handleAddCategory = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    if (categoryName === "") {
      return setError("Category name is required.");
    }

    // Add your API call here to add the category
    try {
      const response = await axios.post(
        "https://maliarakis-server.rhodesislandpass.com/ticket-options/createCategoryOption",
        {
          title: categoryName,
          color: categoryColor,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setSuccess("Category added successfully.");
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setError("Category already exists.");
      } else {
        setError("Failed to add category. Please try again.");
      }
    }

    setCategoryName("");
    setCategoryColor("#FA0808"); // Reset color to default after adding the category
  };

  return (
    <div className="add-category-option-wrapper">
      {error && <Alert severity="error">{error}</Alert>}
      {success && <Alert severity="success">{success}</Alert>}
      <h4
        style={{
          marginBottom: "0px",
          fontWeight: "600",
        }}
      >
        Add Category
      </h4>
      <form
        onSubmit={handleAddCategory}
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          width: "100%",
          alignItems: "center",
        }}
      >
        <TextField
          variant="outlined"
          label="Category Name"
          placeholder="Category Name"
          value={categoryName}
          onChange={(e) => setCategoryName(e.target.value)}
          fullWidth
          margin="normal"
          InputProps={{
            inputProps: { style: { height: "56px", padding: "5px" } }, // Adjust the style as needed
          }}
        />
        <TextField
          variant="outlined"
          type="color"
          label="Label Color"
          value={categoryColor}
          onChange={(e) => setCategoryColor(e.target.value)}
          margin="normal"
          sx={{
            width: "20%",
          }}
          // Custom styling may be required for color input to look consistent
          InputProps={{
            inputProps: { style: { height: "56px", padding: "5px" } }, // Adjust the style as needed
          }}
        />
        <button
          className="add-ticket-button__button"
          sx={{
            backgroundColor: "var(--primary-color)",
            color: "var(--primary-text-color)",
            fontSize: "15px",
            fontWeight: "600",
            width: "auto",
            padding: "10px 20px",
          }}
          onClick={() => {}}
        >
          <AddIcon />
          <span>Add</span>
        </button>
      </form>
    </div>
  );
};

export default AddCategoryOption;
