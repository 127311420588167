import React, { useState, useEffect } from "react";

import { Upload } from "@mui/icons-material";
import axios from "axios";

import { TextField, Alert, Typography, Tabs, Tab, Box } from "@mui/material";

import "./ViewProperty.css";

const makeClickable = (text) => {
  // Regex patterns for detecting URLs, emails, and phone numbers
  const urlPattern = /(https?:\/\/[^\s]+)/g;
  const emailPattern = /(\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b)/gi;
  const phonePattern = /(\b\d{3}[-.]?\d{3}[-.]?\d{4}\b)/g;

  // Replace URLs with anchor tags
  text = text.replace(
    urlPattern,
    (url) => `<a href="${url}" target="_blank">${url}</a>`
  );

  // Replace emails with mailto links
  text = text.replace(
    emailPattern,
    (email) => `<a href="mailto:${email}">${email}</a>`
  );

  // Replace phone numbers with tel links
  text = text.replace(
    phonePattern,
    (phone) => `<a href="tel:${phone}">${phone}</a>`
  );

  return text;
};

const ViewProperty = (props) => {
  const [propertyName, setPropertyName] = useState(props.property.name);
  const [owner_name, setOwnerName] = useState(props.property.owner_name);
  const [owner_email, setOwnerEmail] = useState(props.property.owner_email);
  const [owner_phone, setOwnerPhone] = useState(props.property.owner_phone);
  const [owner_phone2, setOwnerPhone2] = useState(props.property.owner_phone2);
  const [owner_home_number, setOwnerHomeNumber] = useState(
    props.property.owner_home_number
  );
  const [logistic_name, setLogisticName] = useState(
    props.property.logistic_name
  );
  const [logistic_phone, setLogisticPhone] = useState(
    props.property.logistic_phone
  );
  const [logistic_email, setLogisticEmail] = useState(
    props.property.logistic_email
  );
  const [logistic_phone2, setLogisticPhone2] = useState(
    props.property.logistic_phone2
  );
  const [cleaning_name, setCleaningName] = useState(
    props.property.cleaning_name
  );
  const [cleaning_phone, setCleaningPhone] = useState(
    props.property.cleaning_phone
  );
  const [cleaning_email, setCleaningEmail] = useState(
    props.property.cleaning_email
  );
  const [cleaning_phone2, setCleaningPhone2] = useState(
    props.property.cleaning_phone2
  );
  const [maintenance_name, setMaintenanceName] = useState(
    props.property.maintenance_name
  );
  const [maintenance_email, setMaintenanceEmail] = useState(
    props.property.maintenance_email
  );
  const [maintenance_phone, setMaintenancePhone] = useState(
    props.property.maintenance_phone
  );
  const [maintenance_phone2, setMaintenancePhone2] = useState(
    props.property.maintenance_phone2
  );
  const [pool_name, setPoolName] = useState(props.property.pool_name);
  const [pool_phone, setPoolPhone] = useState(props.property.pool_phone);
  const [pool_email, setPoolEmail] = useState(props.property.pool_email);
  const [pool_phone2, setPoolPhone2] = useState(props.property.pool_phone2);
  const [property_location, setPropertyLocation] = useState(
    props.property.property_location
  );
  const [master_lock, setMasterLock] = useState(props.property.master_lock);
  const [wifi_username, setWifiUsername] = useState(
    props.property.wifi_username
  );
  const [wifi_password, setWifiPassword] = useState(
    props.property.wifi_password
  );
  const [manual_link, setManualLink] = useState(props.property.manual_link);
  const [arrivals, setArrivals] = useState(props.property.arrivals);
  const [more_info, setMoreInfo] = useState(props.property.more_info ? props.property.more_info : "");

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
      }}
    >
      <Box
        sx={{
          maxWidth: { xs: 320, sm: 600, md: 1000 },
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          aria-label="scrollable auto tabs example"
          sx={{
            '.MuiTabs-flexContainer': {
              padding: '0 10px', // Adjust padding based on your design needs
            },
            '& .MuiTabs-scroller': {
              flexGrow: '0', // Ensures tabs container does not grow beyond its initial size
            }
          }}
        >
          <Tab label="Property Details" />
          <Tab label="Owner Details" />
          <Tab label="Accounting Details" />
          <Tab label="Cleaning Details" />
          <Tab label="Maintenance Details" />
          <Tab label="Pool Details" />
        </Tabs>
      </Box>

      <div
        style={{
          display: tabValue === 0 ? "block" : "none",
          width: "100%",
          padding: "1rem",
        }}
        className="view-property-info"
      >
        <h4>
          <strong>Property Name:</strong> {propertyName}
        </h4>
        <h4>
          <strong>Property Location:</strong>{" "}
          <p
            dangerouslySetInnerHTML={{
              __html: makeClickable(property_location),
            }}
          ></p>
        </h4>

        <h4>
          <strong>Master Lock:</strong> {master_lock}
        </h4>
        <h4>
          <strong>Wifi Username:</strong> {wifi_username}
        </h4>
        <h4>
          <strong>Wifi Password:</strong> {wifi_password}
        </h4>
        <h4>
          <strong>Manual Link:</strong>{" "}
          <p
            dangerouslySetInnerHTML={{ __html: makeClickable(manual_link) }}
          ></p>
        </h4>
        <h4>
          <strong>Arrivals:</strong>{" "}
          <p dangerouslySetInnerHTML={{ __html: makeClickable(arrivals) }}></p>
        </h4>
        <h4>
          <strong>More Info:</strong>{" "}
          <p dangerouslySetInnerHTML={{ __html: makeClickable(more_info) }}></p>
        </h4>
      </div>

      <div
        style={{
          display: tabValue === 1 ? "block" : "none",
          width: "100%",
          padding: "1rem",
        }}
        className="view-property-info"
      >
        <h4>
          <strong>Owner Name:</strong> {owner_name}
        </h4>
        <h4>
          <strong>Owner Email:</strong>{" "}
          <p
            dangerouslySetInnerHTML={{ __html: makeClickable(owner_email) }}
          ></p>
        </h4>
        <h4>
          <strong>Owner Phone:</strong>{" "}
          <p
            dangerouslySetInnerHTML={{ __html: makeClickable(owner_phone) }}
          ></p>
        </h4>
        <h4>
          <strong>Owner Phone II:</strong>{" "}
          <p
            dangerouslySetInnerHTML={{ __html: makeClickable(owner_phone2) }}
          ></p>
        </h4>
        <h4>
          <strong>Owner Home Number:</strong>{" "}
          <p
            dangerouslySetInnerHTML={{
              __html: makeClickable(owner_home_number),
            }}
          ></p>
        </h4>
      </div>

      <div
        style={{
          display: tabValue === 2 ? "block" : "none",
          width: "100%",
          padding: "1rem",
        }}
        className="view-property-info"
      >
        <h4>
          <strong>Accounting Name:</strong> {logistic_name}
        </h4>
        <h4>
          <strong>Accounting Phone:</strong>{" "}
          <p
            dangerouslySetInnerHTML={{ __html: makeClickable(logistic_phone) }}
          ></p>
        </h4>
        <h4>
          <strong>Accounting Email:</strong>{" "}
          <p
            dangerouslySetInnerHTML={{ __html: makeClickable(logistic_email) }}
          ></p>
        </h4>
        <h4>
          <strong>Accounting Phone II:</strong>{" "}
          <p
            dangerouslySetInnerHTML={{ __html: makeClickable(logistic_phone2) }}
          ></p>
        </h4>
      </div>

      <div
        style={{
          display: tabValue === 3 ? "block" : "none",
          width: "100%",
          padding: "1rem",
        }}
        className="view-property-info"
      >
        <h4>
          <strong>Cleaning Name:</strong> {cleaning_name}
        </h4>
        <h4>
          <strong>Cleaning Phone:</strong>{" "}
          <p
            dangerouslySetInnerHTML={{ __html: makeClickable(cleaning_phone) }}
          ></p>
        </h4>
        <h4>
          <strong>Cleaning Email:</strong>{" "}
          <p
            dangerouslySetInnerHTML={{ __html: makeClickable(cleaning_email) }}
          ></p>
        </h4>
        <h4>
          <strong>Cleaning Phone II:</strong>{" "}
          <p
            dangerouslySetInnerHTML={{ __html: makeClickable(cleaning_phone2) }}
          ></p>
        </h4>
      </div>

      <div
        style={{
          display: tabValue === 4 ? "block" : "none",
          width: "100%",
          padding: "1rem",
        }}
        className="view-property-info"
      >
        <h4>
          <strong>Maintenance Name:</strong> {maintenance_name}
        </h4>
        <h4>
          <strong>Maintenance Phone:</strong>{" "}
          <p
            dangerouslySetInnerHTML={{
              __html: makeClickable(maintenance_phone),
            }}
          ></p>
        </h4>
        <h4>
          <strong>Maintenance Email:</strong>{" "}
          <p
            dangerouslySetInnerHTML={{
              __html: makeClickable(maintenance_email),
            }}
          ></p>
        </h4>
        <h4>
          <strong>Maintenance Phone II:</strong>{" "}
          <p
            dangerouslySetInnerHTML={{
              __html: makeClickable(maintenance_phone2),
            }}
          ></p>
        </h4>
      </div>

      <div
        style={{
          display: tabValue === 5 ? "block" : "none",
          width: "100%",
          padding: "1rem",
        }}
        className="view-property-info"
      >
        <h4>
          <strong>Pool Name:</strong> {pool_name}
        </h4>
        <h4>
          <strong>Pool Phone:</strong>{" "}
          <p
            dangerouslySetInnerHTML={{ __html: makeClickable(pool_phone) }}
          ></p>
        </h4>
        <h4>
          <strong>Pool Email:</strong>{" "}
          <p
            dangerouslySetInnerHTML={{ __html: makeClickable(pool_email) }}
          ></p>
        </h4>
        <h4>
          <strong>Pool Phone II:</strong>{" "}
          <p
            dangerouslySetInnerHTML={{ __html: makeClickable(pool_phone2) }}
          ></p>
        </h4>
      </div>
    </div>
  );
};

export default ViewProperty;
