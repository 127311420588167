import React from "react";

import { CircularProgress, Box } from "@mui/material";

const Loader = ({ message }) => {
  return (
    <>
      <h4 style={
        {
          textAlign: "center",
          paddingTop: "50px",
        }
      
      }>{message}</h4>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          paddingTop: "50px",
          height: "100%",
        }}
      >
        <CircularProgress />
      </Box>
    </>
  );
};
export default Loader;
