import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

import "./Header.css";
import LoginCounter from "../loginCounter/LoginCounter";
import NotificationsPanel from "../notificationsPanel/NotificationsPanel";
import { Logout } from "@mui/icons-material";
import { Tooltip } from "@material-ui/core";
import MobileSidebar from "../mobileSidebar/MobileSidebar";
import{
  Menu
}
from "@mui/icons-material";


const Header = (props) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <div className="header">
      <div className="header__left">
        {/* <img
          src="https://www.instagram.com/static/images/web/mobile_nav_type_logo.png/735145cfe0a4.png"
          alt="Instagram"
        /> */}
        <Menu 
          style={{ cursor: "pointer", color: "var(--primary-color)", fontSize: "30px", 
            display: window.innerWidth <= 768 ? "block" : "none"
          }}
          onClick={() => setDrawerOpen(!drawerOpen)}
        />
        <MobileSidebar 
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
        />
      </div>
      <div className="header__center">
        {/* <input type="text" placeholder="Search" /> */}
      </div>
      <div className="header__right">

        <NotificationsPanel
          playAudio={props.playAudio}
        />
        <LoginCounter />
        <Tooltip title="Logout" placement="bottom">
        <Logout 
          style={{ cursor: "pointer", color: "var(--primary-color)", fontSize: "25px",
            marginLeft: "10px"}}
          onClick={() => {
            localStorage.removeItem("user");
            window.location.href="/";
          }}
        />
        </Tooltip>
      </div>
    </div>
  );
};

export default Header;
