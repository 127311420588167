import React from "react";

import { LoginForm, SignUpForm } from "../../components";
import { AuthForms } from "../../containers";
import "./Auth.css";

const Auth = (props) => {
  return (
    <div className="authForms-wrapper">
      <div className="authForms">
        <AuthForms isLogin={props.isLogin}/>
      </div>
    </div>
  );
};

export default Auth;
