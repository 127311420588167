import React, { useState, useEffect, useRef } from "react";
import { useUser } from "../../contexts/UserContext";

import NotificationSound from "../../sounds/notification-sound.mp3";
import "./PageStructure.css";
import {
  AddTicketButton,
  Header,
  Sidebar,
  TaskManager,
  TicketTable,
} from "../../components";
import { Add, Task } from "@mui/icons-material";

const PageStructure = ({ children }) => {
  const [sidebarWidth, setSidebarWidth] = useState(
    window.innerWidth <= 768 ? "0px" : localStorage.getItem("isCollapsed") === "true" ? "90px" : "250px"
  );
  const audioPlayer = useRef(null);
  function playAudio() {
    audioPlayer.current.play();
  }

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setSidebarWidth("0px");
    } else {
      setSidebarWidth(
        localStorage.getItem("isCollapsed") === "true" ? "90px" : "250px"
      );
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }
  , []);


  return (
    <div className="page-structure-wrapper">
      <div
        className="page-structure-sidebar"
        style={{ width: sidebarWidth, transition: "width 0.6s" }}
      >
        <Sidebar setWidth={setSidebarWidth} />
      </div>

      <div
        className="page-structure-content"
        style={{
          width: `calc(100% - ${sidebarWidth})`,
        }}
      >
        <div className="header-wrapper">
          <audio ref={audioPlayer} src={NotificationSound} />
          <Header playAudio={playAudio} />
        </div>
        <div
          className="page-structure-content-inner"
          style={{
            height: window.innerWidth <= 768 ?  "calc(100vh - 150px)" : "calc(100vh - 100px)",
            overflowY: "auto",
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default PageStructure;
