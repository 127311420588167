import React, { useState, useEffect, useRef } from "react";
import { useUser } from "../../contexts/UserContext";

import "./Users.css";
import { UsersPermissions } from "../../components";
import { PageStructure } from "../../pages";
import { } from "@mui/icons-material";


const Users = () => {
  return (
    <PageStructure>
     <h1>Users' Permissions</h1>


    <UsersPermissions />
       
    </PageStructure>
  );
};
export default Users;
