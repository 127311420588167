import React, { useState } from "react";
import { TextField, Alert } from "@mui/material";
import { Add } from "@mui/icons-material";
import { AddPropertyForm, PopUpWindow } from "..";

import axios from "axios";

const AddPropertyButton = (props) => {
  const [propertyName, setPropertyName] = useState("");
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [buttonWidth, setButtonWidth] = useState(
    window.innerWidth <= 768 ? "100%" : "auto"
  );

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const user = JSON.parse(localStorage.getItem("user"));

  const handleClick = () => {
    setPopUpOpen(true);
  };
  const handleAddProperty = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    if (propertyName === "") {
      return setError("Property name is required.");
    }

    // Add your API call here to add the property
    try {
      const response = await axios.post(
        "https://maliarakis-server.rhodesislandpass.com/property/createProperty",
        {
          title: propertyName,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setSuccess("Property added successfully.");
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setError("Property already exists.");
      } else {
        setError("Failed to add property. Please try again.");
      }
    }

    setPropertyName("");
  };

  return (
    <>
      <div
        className="add-ticket-button"
        style={{
          display: JSON.parse(localStorage.getItem("user")).permissions
            .canAddProperty
            ? "flex"
            : "none",
        }}
      >
        <button
          className="add-ticket-button__button"
          style={{
            backgroundColor: props.color || "var(--primary-color)",
            color: props.textColor || "var(--primary-text-color)",
            fontSize: props.fontSize || "15px",
            fontWeight: props.fontWeight || "600",
            width: props.width || buttonWidth,
          }}
          onClick={handleClick}
        >
          <Add />
          <span>Add Property</span>
        </button>
      </div>
      {popUpOpen && (
        <PopUpWindow
          onClose={() => {
            setPopUpOpen(false);
            setError("");
            setSuccess("");
          }}
        >
          <AddPropertyForm setIsOpen={setPopUpOpen} isEdit={false} />
        </PopUpWindow>
      )}
    </>
  );
};

export default AddPropertyButton;
