import React, { useState, useEffect, useRef } from "react";
import { useUser } from "../../contexts/UserContext";

import "./Reservations.css";

import {
  AddReservationButton,
  Sidebar,
  TaskManager,
  ReservationTable,
  Loader,
} from "../../components";
import { Add, Task } from "@mui/icons-material";
import { PageStructure } from "../../pages";

const Reservations = () => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <PageStructure>
      <h1>Reservations</h1>
      <h2>Reservations</h2>
      {isLoading && <Loader />}
      <div
        style={{
          // visibility: isLoading ? "hidden" : "visible",
        }}
      >
        <AddReservationButton setIsLoading={setIsLoading} />

        <ReservationTable />
      </div>
    </PageStructure>
  );
};

export default Reservations;
